import React from 'react';
import MailConfirmedContent from 'components/authentication/MailConfirmedContent';

const MailConfirmed = () => (
  <div className="text-center">
    <MailConfirmedContent />
  </div>
);

export default MailConfirmed;
