import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
// import NavbarTop from 'components/navbar/top/NavbarTop';
// import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import CourseProvider from 'components/app/e-learning/CourseProvider';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';

const OnboardingLayout = () => {
  const { hash, pathname } = useLocation();
  // const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');
  const { authLoading, user } = useContext(FirebaseAuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authLoading) return;
    if (!user) navigate('/login');
  }, [authLoading, user]);

  const {
    config: { isFluid }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // // check to see current onboarding step, if incomplete redirect to onboarding
  // useEffect(() => {
  //   console.log('checking onboarding status', user?.onBoarding?.status);
  //   if (authLoading) return;
  //   if (!user) return;
  //   if (user.onBoarding?.status === 'COMPLETE') {
  //     navigate('/dashboard');
  //   } else {
  //     navigate('/onboarding');
  //   }
  // }, [authLoading, user]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <ProductProvider>
        <CourseProvider>
          <div className={classNames('content', 'mt-4')}>
            {/*------ Main Routes ------*/}
            <Outlet context={[authLoading, user]} />
            <Footer />
          </div>
        </CourseProvider>
      </ProductProvider>
    </div>
  );
};

export default OnboardingLayout;
