import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import WelcomeStep from 'components/pages/onboarding/steps/WelcomeStep';
import NewVendorInformationStep from 'components/pages/onboarding/steps/NewVendorInformationStep';
import PaymentDetailsStep from 'components/pages/onboarding/steps/PaymentDetailsStep';
import ErrorStep from 'components/pages/onboarding/steps/ErrorStep';
import UploadW9Step from 'components/pages/onboarding/steps/UploadW9Step';
import Pending from 'components/pages/onboarding/steps/Pending';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'config/firebase';

const Onboarding = () => {
  const [authLoading, user] = useOutletContext();
  const [step, setStep] = useState(1);
  // const navigate = useNavigate();

  async function getCurrentStep(uid) {
    if (!uid) return;
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    const user = userDoc.data();
    // if (user.onBoarding.status === 'COMPLETE') navigate('/dashboard');
    if (user.onBoarding?.currentStep) {
      setStep(user.onBoarding.currentStep);
    }
  }

  useEffect(() => {
    let isSubscribed = true;
    if (authLoading) return;
    if (!user) return;
    if (isSubscribed) getCurrentStep(user.uid);
    return () => (isSubscribed = false);
  }, [authLoading, user]);

  let DisplayComponent;
  switch (step) {
    case 1:
      DisplayComponent = WelcomeStep;
      break;
    case 2:
      DisplayComponent = NewVendorInformationStep;
      break;
    case 3:
      DisplayComponent = PaymentDetailsStep;
      break;
    case 4:
      DisplayComponent = UploadW9Step;
      break;
    case 5:
      DisplayComponent = Pending;
      break;
    default:
      DisplayComponent = ErrorStep;
      break;
  }

  return !authLoading && user && <DisplayComponent setStep={setStep} />;
};

export default Onboarding;

Onboarding.propTypes = {
  authLoading: PropTypes.bool,
  user: PropTypes.object
};
