/* eslint-disable react/prop-types */
import React, { useContext, createContext, useState, useEffect } from 'react';
import { auth } from 'config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'config/firebase';
export const FirebaseAuthContext = createContext();

async function gatherAdditionalUserData(uid) {
  try {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();
    return userData;
  } catch (error) {
    console.log(error);
  }
}

export const FirebaseAuthProvider = ({ children }) => {
  const [userMasterData, setUserMasterData] = useState(null);
  const [user, authLoading] = useAuthState(auth);

  useEffect(() => {
    let isSubscribed = true;
    if (authLoading) return;
    if (!user) return;
    gatherAdditionalUserData(user.uid).then(userData => {
      if (isSubscribed) setUserMasterData(userData);
    });
    return () => (isSubscribed = false);
  }, [user, authLoading]);

  const value = { user: user, details: userMasterData, authLoading };
  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export function useFirebaseAuth() {
  const context = useContext(FirebaseAuthContext);
  if (context === undefined)
    throw new Error('Must be used within FirebaseAuthProvider');
  return [context.user, context.authLoading];
}
