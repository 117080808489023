import React from 'react';
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';

const ProductPricing = () => {
  const {
    register,
    setValue,
    // watch,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Pricing
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col xs="8">
            <Form.Group controlId="srpPrice">
              <Form.Label>
                Item SRP
                <OverlayTrigger
                  overlay={
                    <Tooltip style={{ position: 'fixed' }} id="srpPriceTooltip">
                      Suggested Retail Price per Each Unit
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-primary fs--1">
                    <FontAwesomeIcon icon="question-circle" />
                  </span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                {...register('srpPrice', {
                  onChange: e => setValue('srpPrice', e.target.value)
                })}
                isInvalid={!!errors.srpPrice}
              />
              <Form.Control.Feedback type="invalid">
                {errors.regularPrice?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs="4">
            <Form.Group>
              <Form.Label>Currency:</Form.Label>
              <Form.Select {...register(`srpCurrency`)}>
                <option value="usd">USD</option>
                <option value="eur">EUR</option>
                <option value="gbp">GBP</option>
                <option value="cad">CAD</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs="12">
            <Form.Group controlId="prepricedQuery">
              <Form.Check
                type="checkbox"
                label="Each Unit is Pre-Priced"
                {...register('prepricedQuery')}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductPricing;
