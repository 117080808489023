export const levelOne = [
  {
    id: 1,
    categoryName: 'Electronics & Gadgets',
    categoryDetail: 'Tech devices like phones, cameras, drones, etc.'
  },
  {
    id: 2,
    categoryName: 'Fashion & Apparel',
    categoryDetail: 'Clothing, shoes, fashion accessories.'
  },
  {
    id: 3,
    categoryName: 'Home & Living',
    categoryDetail: 'Home decor, furniture, kitchenware, household items.'
  },
  {
    id: 4,
    categoryName: 'Health & Beauty',
    categoryDetail: 'Skincare, cosmetics, health devices, supplements.'
  },
  {
    id: 5,
    categoryName: 'Sports & Outdoors',
    categoryDetail: 'Athletic gear, camping equipment, sporting goods.'
  },
  {
    id: 6,
    categoryName: 'Automotive & Industrial',
    categoryDetail: 'Vehicles, car parts, machinery, tools.'
  },
  {
    id: 7,
    categoryName: 'Business & Industrial Supplies',
    categoryDetail:
      'Office supplies, equipment, industrial materials, catering supplies.'
  },
  {
    id: 8,
    categoryName: 'Media',
    categoryDetail: 'Books, CDs, vinyl, movies, magazines, digital downloads.'
  },
  {
    id: 9,
    categoryName: 'Toys, Kids & Babies',
    categoryDetail: 'Products for children, toys for all ages.'
  },
  {
    id: 10,
    categoryName: 'Jewelry & Watches',
    categoryDetail: 'All kinds of personal adornments.'
  },
  {
    id: 11,
    categoryName: 'Food & Beverages',
    categoryDetail: 'Gourmet foods, drinks, specialty groceries.'
  },
  {
    id: 12,
    categoryName: 'Hobbies & Craft',
    categoryDetail: 'DIY kits, collectibles, musical instruments.'
  },
  {
    id: 13,
    categoryName: 'Pets & Animals',
    categoryDetail: 'Everything for our non-human companions.'
  },
  {
    id: 14,
    categoryName: 'Garden & Outdoors',
    categoryDetail: 'Plants, gardening tools, outdoor furniture.'
  },
  {
    id: 15,
    categoryName: 'Services & Experiences',
    categoryDetail: 'Workshops, courses, event tickets.'
  }
];

export const levelTwo = [
  {
    1: [
      {
        id: '1.1',
        categoryName: 'Mobile Phones & Accessories',
        categoryDetail: 'Smartphones, cases, screen protectors.'
      },
      {
        id: '1.2',
        categoryName: 'Cameras & Photography',
        categoryDetail: 'Digital cameras, lenses, tripods.'
      },
      {
        id: '1.3',
        categoryName: 'Computers & Laptops',
        categoryDetail: 'Desktops, laptops, software.'
      },
      {
        id: '1.4',
        categoryName: 'Audio & Sound Equipment',
        categoryDetail: 'Headphones, microphones, speakers.'
      },
      {
        id: '1.5',
        categoryName: 'Drones & Remote-Controlled Devices',
        categoryDetail: 'Drones, RC cars, hobby kits.'
      },
      {
        id: '1.6',
        categoryName: 'Wearable Technology',
        categoryDetail: 'Smartwatches, fitness trackers, VR headsets.'
      },
      {
        id: '1.7',
        categoryName: 'Smart Home Devices',
        categoryDetail: 'Smart lights, thermostats, security systems.'
      },
      {
        id: '1.8',
        categoryName: 'Gaming Consoles & Accessories',
        categoryDetail: 'Consoles, games, controllers.'
      },
      {
        id: '1.9',
        categoryName: 'Chargers & Cables',
        categoryDetail: 'Phone chargers, HDMI cables, adapters.'
      },
      {
        id: '1.10',
        categoryName: 'TV & Video Equipment',
        categoryDetail: 'Televisions, Blu-ray players, projectors.'
      }
    ]
  },
  {
    2: [
      {
        id: '2.1',
        categoryName: "Men's Clothing",
        categoryDetail: 'Shirts, pants, jackets, suits.'
      },
      {
        id: '2.2',
        categoryName: "Women's Clothing",
        categoryDetail: 'Dresses, blouses, skirts, suits.'
      },
      {
        id: '2.3',
        categoryName: "Children's Clothing",
        categoryDetail: 'Shirts, pants, dresses, school uniforms.'
      },
      {
        id: '2.4',
        categoryName: 'Shoes',
        categoryDetail: 'Sneakers, heels, boots, sandals.'
      },
      {
        id: '2.5',
        categoryName: 'Handbags & Purses',
        categoryDetail: 'Totes, satchels, wallets, crossbody bags.'
      },
      {
        id: '2.6',
        categoryName: 'Jewelry',
        categoryDetail: 'Rings, necklaces, earrings, bracelets.'
      },
      {
        id: '2.7',
        categoryName: 'Watches',
        categoryDetail: 'Analog, digital, luxury, sports.'
      },
      {
        id: '2.8',
        categoryName: 'Sunglasses & Eyewear',
        categoryDetail: 'Sunglasses, prescription glasses, contact lenses.'
      },
      {
        id: '2.9',
        categoryName: 'Hats & Caps',
        categoryDetail: 'Baseball caps, beanies, fedoras, sun hats.'
      },
      {
        id: '2.10',
        categoryName: 'Swimwear',
        categoryDetail: 'Bikinis, one-pieces, trunks, rash guards.'
      }
    ]
  },
  {
    3: [
      {
        id: '3.1',
        categoryName: 'Furniture',
        categoryDetail: 'Sofas, tables, chairs, wardrobes.'
      },
      {
        id: '3.2',
        categoryName: 'Home Decor',
        categoryDetail: 'Cushions, wall art, vases, candles.'
      },
      {
        id: '3.3',
        categoryName: 'Gardening Tools',
        categoryDetail: 'Shovels, rakes, pruners, watering cans.'
      },
      {
        id: '3.4',
        categoryName: 'Outdoor Furniture',
        categoryDetail: 'Patio sets, umbrellas, loungers.'
      },
      {
        id: '3.5',
        categoryName: 'Kitchen & Dining',
        categoryDetail: 'Cookware, tableware, utensils, appliances.'
      },
      {
        id: '3.6',
        categoryName: 'Bedding & Bath',
        categoryDetail: 'Bed sheets, quilts, towels, shower curtains.'
      },
      {
        id: '3.7',
        categoryName: 'Lighting',
        categoryDetail: 'Lamps, chandeliers, outdoor lights.'
      },
      {
        id: '3.8',
        categoryName: 'Storage & Organization',
        categoryDetail: 'Baskets, shelving units, hooks, bins.'
      },
      {
        id: '3.9',
        categoryName: 'Cleaning Supplies',
        categoryDetail: 'Brooms, mops, cleaning agents, vacuum cleaners.'
      },
      {
        id: '3.10',
        categoryName: 'Home Improvement',
        categoryDetail: 'Paint, tools, fixtures, tiles.'
      },
      {
        id: '3.11',
        categoryName: 'Plants & Plant Care',
        categoryDetail: 'Indoor plants, seeds, fertilizers, pots.'
      }
    ]
  },
  {
    4: [
      {
        id: '4.1',
        categoryName: 'Personal Care',
        categoryDetail: 'Toothbrushes, soaps, razors, hygiene items.'
      },
      {
        id: '4.2',
        categoryName: 'Hair Care',
        categoryDetail: 'Shampoos, conditioners, hair dyes, styling products.'
      },
      {
        id: '4.3',
        categoryName: 'Skin Care',
        categoryDetail: 'Creams, lotions, face masks, sun protection.'
      },
      {
        id: '4.4',
        categoryName: 'Makeup',
        categoryDetail: 'Lipstick, mascara, foundation, eye shadow.'
      },
      {
        id: '4.5',
        categoryName: 'Fragrances',
        categoryDetail: 'Perfumes, colognes, body mists.'
      },
      {
        id: '4.6',
        categoryName: 'Health Supplements',
        categoryDetail: 'Vitamins, probiotics, protein powders.'
      },
      {
        id: '4.7',
        categoryName: 'Fitness Equipment',
        categoryDetail: 'Dumbbells, yoga mats, resistance bands.'
      },
      {
        id: '4.8',
        categoryName: 'Pharmacy',
        categoryDetail: 'Over-the-counter medicines, first aid, prescriptions.'
      },
      {
        id: '4.9',
        categoryName: 'Bath & Body',
        categoryDetail: 'Body wash, bath bombs, bubble bath.'
      },
      {
        id: '4.10',
        categoryName: 'Nail Care',
        categoryDetail: 'Nail polish, manicure sets, nail care tools.'
      },
      {
        id: '4.11',
        categoryName: 'Eye Care',
        categoryDetail: 'Contact lenses, solutions, eye drops.'
      },
      {
        id: '4.12',
        categoryName: "Men's Grooming",
        categoryDetail: "Shaving kits, beard care, men's skin care."
      }
    ]
  },
  {
    5: [
      {
        id: '5.1',
        categoryName: 'Team Sports',
        categoryDetail: 'Soccer, basketball, baseball gear, and accessories.'
      },
      {
        id: '5.2',
        categoryName: 'Outdoor Recreation',
        categoryDetail: 'Camping, hiking, fishing gear.'
      },
      {
        id: '5.3',
        categoryName: 'Exercise & Fitness',
        categoryDetail: 'Treadmills, weights, fitness trackers.'
      },
      {
        id: '5.4',
        categoryName: 'Water Sports',
        categoryDetail: 'Swimwear, scuba diving, kayaking equipment.'
      },
      {
        id: '5.5',
        categoryName: 'Winter Sports',
        categoryDetail: 'Skiing, snowboarding, ice skating gear.'
      },
      {
        id: '5.6',
        categoryName: 'Cycling',
        categoryDetail: 'Bicycles, helmets, cycling wear.'
      },
      {
        id: '5.7',
        categoryName: 'Golf',
        categoryDetail: 'Golf clubs, balls, attire.'
      },
      {
        id: '5.8',
        categoryName: 'Racket Sports',
        categoryDetail: 'Tennis, badminton, squash equipment.'
      },
      {
        id: '5.9',
        categoryName: 'Combat Sports',
        categoryDetail: 'Boxing gloves, martial arts uniforms.'
      },
      {
        id: '5.10',
        categoryName: 'Running & Athletics',
        categoryDetail: 'Running shoes, athletic wear, hydration packs.'
      },
      {
        id: '5.11',
        categoryName: 'Equestrian',
        categoryDetail: 'Riding boots, saddles, horse grooming tools.'
      },
      {
        id: '5.12',
        categoryName: 'Sports Nutrition',
        categoryDetail: 'Energy bars, electrolyte drinks, protein shakes.'
      },
      {
        id: '5.13',
        categoryName: 'Outdoor Apparel',
        categoryDetail:
          'Weatherproof jackets, hiking boots, UV-protective wear.'
      }
    ]
  },
  {
    6: [
      {
        id: '6.1',
        categoryName: 'Vehicle Parts & Accessories',
        categoryDetail: 'Engine components, car audio, tires.'
      },
      {
        id: '6.2',
        categoryName: 'Tools & Equipment',
        categoryDetail: 'Hand tools, power tools, welding tools.'
      },
      {
        id: '6.3',
        categoryName: 'Automotive Care',
        categoryDetail: 'Car wash solutions, waxes, polishes.'
      },
      {
        id: '6.4',
        categoryName: 'Industrial Supplies',
        categoryDetail: 'Adhesives, safety equipment, janitorial supplies.'
      },
      {
        id: '6.5',
        categoryName: 'Heavy Machinery',
        categoryDetail: 'Excavators, tractors, cranes.'
      },
      {
        id: '6.6',
        categoryName: 'Construction Materials',
        categoryDetail: 'Lumber, concrete, insulation.'
      },
      {
        id: '6.7',
        categoryName: 'Fleet & Vehicle Management',
        categoryDetail: 'Telematics systems, fuel management.'
      },
      {
        id: '6.8',
        categoryName: 'Factory & Manufacturing Equipment',
        categoryDetail: 'Conveyors, robotics, assembly line tools.'
      },
      {
        id: '6.9',
        categoryName: 'Warehouse & Storage',
        categoryDetail: 'Shelving, pallets, bins.'
      },
      {
        id: '6.10',
        categoryName: 'Safety & Security',
        categoryDetail: 'Helmets, safety vests, fire alarms.'
      },
      {
        id: '6.11',
        categoryName: 'Laboratory & Scientific Supplies',
        categoryDetail: 'Test tubes, microscopes, chemicals.'
      },
      {
        id: '6.12',
        categoryName: 'Power & Energy',
        categoryDetail: 'Generators, batteries, solar panels.'
      },
      {
        id: '6.13',
        categoryName: 'Transport & Cargo',
        categoryDetail: 'Shipping containers, pallet trucks, load binders.'
      },
      {
        id: '6.14',
        categoryName: 'Agricultural Equipment',
        categoryDetail: 'Harvesters, seeders, plows.'
      }
    ]
  },
  {
    7: [
      {
        id: '7.1',
        categoryName: 'Office Supplies',
        categoryDetail: 'Pens, paper, folders, staplers.'
      },
      {
        id: '7.2',
        categoryName: 'Office Furniture',
        categoryDetail: 'Desks, chairs, cabinets.'
      },
      {
        id: '7.3',
        categoryName: 'Office Electronics',
        categoryDetail: 'Printers, projectors, shredders.'
      },
      {
        id: '7.4',
        categoryName: 'Restaurant & Foodservice',
        categoryDetail: 'Cookware, tableware, appliances.'
      },
      {
        id: '7.5',
        categoryName: 'Janitorial & Cleaning Supplies',
        categoryDetail: 'Vacuum cleaners, mops, cleaning chemicals.'
      },
      {
        id: '7.6',
        categoryName: 'Point of Sale & Money Handling',
        categoryDetail: 'Cash registers, barcode scanners, coin counters.'
      },
      {
        id: '7.7',
        categoryName: 'Retail & Services',
        categoryDetail: 'Shopping carts, price tags, mannequins.'
      },
      {
        id: '7.8',
        categoryName: 'Safety & Security Equipment',
        categoryDetail: 'Surveillance cameras, safes, alarms.'
      },
      {
        id: '7.9',
        categoryName: 'Material Handling Equipment',
        categoryDetail: 'Forklifts, conveyor belts, hoists.'
      },
      {
        id: '7.10',
        categoryName: 'Building Materials & Supplies',
        categoryDetail: 'Lumber, bricks, insulation.'
      },
      {
        id: '7.11',
        categoryName: 'Industrial Plumbing & Fixtures',
        categoryDetail: 'Pipes, valves, fittings.'
      },
      {
        id: '7.12',
        categoryName: 'Electrical Equipment & Supplies',
        categoryDetail: 'Circuit breakers, cables, switches.'
      },
      {
        id: '7.13',
        categoryName: 'Laboratory Equipment',
        categoryDetail: 'Microscopes, lab coats, petri dishes.'
      },
      {
        id: '7.14',
        categoryName: 'Agricultural Supplies',
        categoryDetail: 'Fertilizers, seeds, farming tools.'
      },
      {
        id: '7.15',
        categoryName: 'HVAC & Refrigeration',
        categoryDetail: 'Air conditioners, heaters, refrigeration units.'
      }
    ]
  },
  {
    8: [
      {
        id: '8.1',
        categoryName: 'Books',
        categoryDetail: 'Novels, textbooks, audiobooks.'
      },
      {
        id: '8.2',
        categoryName: 'Music',
        categoryDetail: 'CDs, vinyls, digital downloads.'
      },
      {
        id: '8.3',
        categoryName: 'Movies & TV Shows',
        categoryDetail: 'DVDs, Blu-rays, digital movies.'
      },
      {
        id: '8.4',
        categoryName: 'Magazines & Periodicals',
        categoryDetail: 'Weekly, monthly, annual publications.'
      },
      {
        id: '8.5',
        categoryName: 'Comics & Graphic Novels',
        categoryDetail: 'Superhero stories, manga, indie comics.'
      },
      {
        id: '8.6',
        categoryName: 'Video Games',
        categoryDetail: 'Console games, PC games, digital codes.'
      },
      {
        id: '8.7',
        categoryName: 'Software',
        categoryDetail: 'Operating systems, applications, utilities.'
      },
      {
        id: '8.8',
        categoryName: 'Digital Downloads',
        categoryDetail: 'E-books, music, software licenses.'
      },
      {
        id: '8.9',
        categoryName: 'Podcasts & Audio Shows',
        categoryDetail: 'Interviews, narratives, educational content.'
      },
      {
        id: '8.10',
        categoryName: 'Educational Media',
        categoryDetail: 'Courses, webinars, training videos.'
      },
      {
        id: '8.11',
        categoryName: 'Music Instruments & Sheet Music',
        categoryDetail: 'Guitars, keyboards, scores.'
      }
    ]
  },
  {
    9: [
      {
        id: '9.1',
        categoryName: 'Toys',
        categoryDetail: 'Action figures, dolls, educational toys.'
      },
      {
        id: '9.2',
        categoryName: 'Board & Card Games',
        categoryDetail: 'Family games, strategy games, card decks.'
      },
      {
        id: '9.3',
        categoryName: 'Outdoor Play',
        categoryDetail: 'Playsets, swings, trampolines.'
      },
      {
        id: '9.4',
        categoryName: 'Baby Gear',
        categoryDetail: 'Strollers, carriers, car seats.'
      },
      {
        id: '9.5',
        categoryName: 'Nursery',
        categoryDetail: 'Cribs, bedding, decor.'
      },
      {
        id: '9.6',
        categoryName: 'Feeding & Nursing',
        categoryDetail: 'Bottles, high chairs, breastfeeding supplies.'
      },
      {
        id: '9.7',
        categoryName: 'Bath & Skincare',
        categoryDetail: 'Baby shampoo, lotions, bathtubs.'
      },
      {
        id: '9.8',
        categoryName: 'Diapering & Potty Training',
        categoryDetail: 'Diapers, wipes, potties.'
      },
      {
        id: '9.9',
        categoryName: "Kids' Clothing & Shoes",
        categoryDetail: 'Dresses, suits, sneakers.'
      },
      {
        id: '9.10',
        categoryName: "Kids' Books & Media",
        categoryDetail: "Picture books, movies, children's music."
      },
      {
        id: '9.11',
        categoryName: 'Safety',
        categoryDetail: 'Baby monitors, gates, cabinet locks.'
      },
      {
        id: '9.12',
        categoryName: 'Travel Gear',
        categoryDetail: 'Travel beds, portable boosters, backpacks.'
      }
    ]
  },
  {
    10: [
      {
        id: '10.1',
        categoryName: 'Fine Jewelry',
        categoryDetail: 'Gold, diamonds, precious gems.'
      },
      {
        id: '10.2',
        categoryName: 'Fashion Jewelry',
        categoryDetail: 'Statement pieces, costume jewelry.'
      },
      {
        id: '10.3',
        categoryName: 'Handmade Jewelry',
        categoryDetail: 'Artisan-crafted, unique designs.'
      },
      {
        id: '10.4',
        categoryName: "Men's Jewelry",
        categoryDetail: 'Cufflinks, rings, necklaces.'
      },
      {
        id: '10.5',
        categoryName: "Children's Jewelry",
        categoryDetail: 'Bracelets, pendants, earrings for kids.'
      },
      {
        id: '10.6',
        categoryName: 'Watches',
        categoryDetail: 'Luxury, casual, smartwatches.'
      },
      {
        id: '10.7',
        categoryName: 'Watch Accessories',
        categoryDetail: 'Bands, batteries, repair kits.'
      },
      {
        id: '10.8',
        categoryName: 'Body Jewelry',
        categoryDetail: 'Piercings, anklets, toe rings.'
      },
      {
        id: '10.9',
        categoryName: 'Jewelry Sets',
        categoryDetail: 'Matching earrings, necklace, and bracelet.'
      },
      {
        id: '10.10',
        categoryName: 'Jewelry Storage & Care',
        categoryDetail: 'Organizers, cleaners, boxes.'
      },
      {
        id: '10.11',
        categoryName: 'Custom & Personalized Jewelry',
        categoryDetail: 'Name necklaces, birthstone rings.'
      }
    ]
  },
  {
    11: [
      {
        id: '11.1',
        categoryName: 'Fruits & Vegetables',
        categoryDetail: 'Fresh, dried, frozen, canned.'
      },
      {
        id: '11.2',
        categoryName: 'Meat & Seafood',
        categoryDetail: 'Beef, poultry, fish, exotic meats.'
      },
      {
        id: '11.3',
        categoryName: 'Dairy & Eggs',
        categoryDetail: 'Milk, yogurt, butter, cheese.'
      },
      {
        id: '11.4',
        categoryName: 'Bakery & Pastries',
        categoryDetail: 'Breads, cakes, cookies, bagels.'
      },
      {
        id: '11.5',
        categoryName: 'Snacks & Sweets',
        categoryDetail: 'Chips, candies, nuts, chocolates.'
      },
      {
        id: '11.6',
        categoryName: 'Cereals & Breakfast Foods',
        categoryDetail: 'Oats, breakfast bars, toasts.'
      },
      {
        id: '11.7',
        categoryName: 'Pasta, Rice & Grains',
        categoryDetail: 'Spaghetti, quinoa, jasmine rice.'
      },
      {
        id: '11.8',
        categoryName: 'Condiments & Sauces',
        categoryDetail: 'Ketchup, mustard, hot sauce, dips.'
      },
      {
        id: '11.9',
        categoryName: 'Drinks & Beverages',
        categoryDetail: 'Sodas, teas, coffee, energy drinks.'
      },
      {
        id: '11.10',
        categoryName: 'Alcoholic Beverages',
        categoryDetail: 'Wines, spirits, beers, mixers.'
      },
      {
        id: '11.11',
        categoryName: 'Organic & Gluten-Free',
        categoryDetail: 'Health-focused, diet-specific foods.'
      },
      {
        id: '11.12',
        categoryName: 'International Cuisine',
        categoryDetail: 'Asian, Mediterranean, Latin, etc.'
      },
      {
        id: '11.13',
        categoryName: 'Ready-to-Eat & Prepared Meals',
        categoryDetail: 'Frozen dinners, meal kits.'
      },
      {
        id: '11.14',
        categoryName: 'Spices & Seasonings',
        categoryDetail: 'Herbs, pepper, salts, blends.'
      },
      {
        id: '11.15',
        categoryName: 'Oils & Vinegars',
        categoryDetail: 'Olive oil, sesame oil, balsamic.'
      },
      {
        id: '11.16',
        categoryName: 'Diet & Nutritional Supplements',
        categoryDetail: 'Proteins, vitamins, wellness drinks.'
      }
    ]
  },
  {
    12: [
      {
        id: '12.1',
        categoryName: 'Arts & Painting Supplies',
        categoryDetail: 'Acrylics, watercolors, canvases.'
      },
      {
        id: '12.2',
        categoryName: 'Craft Kits & Projects',
        categoryDetail: 'DIY kits, model kits, bead sets.'
      },
      {
        id: '12.3',
        categoryName: 'Sewing & Textiles',
        categoryDetail: 'Fabrics, threads, patterns.'
      },
      {
        id: '12.4',
        categoryName: 'Scrapbooking & Paper Crafts',
        categoryDetail: 'Stamps, papers, stickers.'
      },
      {
        id: '12.5',
        categoryName: 'Jewelry Making',
        categoryDetail: 'Beads, wires, tools.'
      },
      {
        id: '12.6',
        categoryName: 'Ceramics & Pottery',
        categoryDetail: 'Clay, tools, glazes.'
      },
      {
        id: '12.7',
        categoryName: 'Woodworking',
        categoryDetail: 'Timber, carving tools, plans.'
      },
      {
        id: '12.8',
        categoryName: 'Photography',
        categoryDetail: 'Cameras, lenses, accessories.'
      },
      {
        id: '12.9',
        categoryName: 'Musical Instruments',
        categoryDetail: 'Guitars, keyboards, drums.'
      },
      {
        id: '12.10',
        categoryName: 'Puzzles & Board Games',
        categoryDetail: 'Jigsaws, strategy games, dice.'
      },
      {
        id: '12.11',
        categoryName: 'Model & RC Vehicles',
        categoryDetail: 'Cars, drones, airplanes.'
      },
      {
        id: '12.12',
        categoryName: 'Collectibles & Memorabilia',
        categoryDetail: 'Stamps, coins, vintage items.'
      },
      {
        id: '12.13',
        categoryName: 'Knitting & Crocheting',
        categoryDetail: 'Yarns, needles, patterns.'
      },
      {
        id: '12.14',
        categoryName: 'Gardening & Floral',
        categoryDetail: 'Seeds, tools, dried flowers.'
      },
      {
        id: '12.15',
        categoryName: 'Candle & Soap Making',
        categoryDetail: 'Waxes, molds, scents.'
      },
      {
        id: '12.16',
        categoryName: 'Drawing & Illustration',
        categoryDetail: 'Pencils, inks, sketchbooks.'
      }
    ]
  },
  {
    13: [
      {
        id: '13.1',
        categoryName: 'Dogs',
        categoryDetail: 'Food, toys, grooming, health supplies.'
      },
      {
        id: '13.2',
        categoryName: 'Cats',
        categoryDetail: 'Litter, toys, food, grooming.'
      },
      {
        id: '13.3',
        categoryName: 'Fish & Aquatic Pets',
        categoryDetail: 'Tanks, food, accessories.'
      },
      {
        id: '13.4',
        categoryName: 'Birds',
        categoryDetail: 'Cages, food, toys.'
      },
      {
        id: '13.5',
        categoryName: 'Small Animals',
        categoryDetail: 'Rabbits, hamsters, guinea pigs essentials.'
      },
      {
        id: '13.6',
        categoryName: 'Reptiles & Amphibians',
        categoryDetail: 'Tanks, lighting, food.'
      },
      {
        id: '13.7',
        categoryName: 'Horses',
        categoryDetail: 'Feed, grooming, riding gear.'
      },
      {
        id: '13.8',
        categoryName: 'Farm Animals',
        categoryDetail: 'Poultry, cattle, goat essentials.'
      },
      {
        id: '13.9',
        categoryName: 'Wildlife',
        categoryDetail: 'Feeders, habitats, care essentials.'
      },
      {
        id: '13.10',
        categoryName: 'Pet Health & Wellness',
        categoryDetail: 'Supplements, health monitors, grooming.'
      },
      {
        id: '13.11',
        categoryName: 'Pet Apparel & Accessories',
        categoryDetail: 'Clothes, collars, costumes.'
      },
      {
        id: '13.12',
        categoryName: 'Pet Training & Behavior',
        categoryDetail: 'Training tools, behavior aids.'
      },
      {
        id: '13.13',
        categoryName: 'Pet Carriers & Travel',
        categoryDetail: 'Carriers, car accessories.'
      },
      {
        id: '13.14',
        categoryName: 'Pet Habitat & Housing',
        categoryDetail: 'Cages, bedding, tanks.'
      },
      {
        id: '13.15',
        categoryName: 'Pet Cleaning & Sanitation',
        categoryDetail: 'Cleaners, deodorizers, litter management.'
      }
    ]
  },
  {
    14: [
      {
        id: '14.1',
        categoryName: 'Plants & Seeds',
        categoryDetail: 'Flowers, trees, vegetable seeds.'
      },
      {
        id: '14.2',
        categoryName: 'Gardening Tools',
        categoryDetail: 'Trowels, shears, gloves.'
      },
      {
        id: '14.3',
        categoryName: 'Plant Care & Accessories',
        categoryDetail: 'Fertilizers, soil, pots.'
      },
      {
        id: '14.4',
        categoryName: 'Outdoor Furniture',
        categoryDetail: 'Benches, hammocks, umbrellas.'
      },
      {
        id: '14.5',
        categoryName: 'Outdoor Decor',
        categoryDetail: 'Garden statues, wind chimes, flags.'
      },
      {
        id: '14.6',
        categoryName: 'Barbecue & Grilling',
        categoryDetail: 'Grills, tools, charcoal.'
      },
      {
        id: '14.7',
        categoryName: 'Pests & Weed Control',
        categoryDetail: 'Repellents, herbicides.'
      },
      {
        id: '14.8',
        categoryName: 'Watering Equipment',
        categoryDetail: 'Hoses, sprinklers, drip systems.'
      },
      {
        id: '14.9',
        categoryName: 'Outdoor Power Equipment',
        categoryDetail: 'Lawnmowers, chainsaws, leaf blowers.'
      },
      {
        id: '14.10',
        categoryName: 'Outdoor Heating & Lighting',
        categoryDetail: 'Fire pits, lanterns, heaters.'
      },
      {
        id: '14.11',
        categoryName: 'Sheds & Outdoor Storage',
        categoryDetail: 'Sheds, deck boxes, greenhouses.'
      },
      {
        id: '14.12',
        categoryName: 'Pools & Spas',
        categoryDetail: 'Pools, saunas, hot tubs, equipment.'
      },
      {
        id: '14.13',
        categoryName: 'Outdoor Games & Sports',
        categoryDetail: 'Bocce, horseshoes, lawn games.'
      },
      {
        id: '14.14',
        categoryName: 'Fencing & Landscaping',
        categoryDetail: 'Fences, gates, landscape fabric.'
      },
      {
        id: '14.15',
        categoryName: 'Composting & Yard Waste',
        categoryDetail: 'Compost bins, bags, mulchers.'
      },
      {
        id: '14.16',
        categoryName: 'Bird & Wildlife',
        categoryDetail: 'Birdhouses, feeders, baths.'
      }
    ]
  },
  {
    15: [
      {
        id: '15.1',
        categoryName: 'Educational Courses',
        categoryDetail: 'Online classes, lectures, seminars.'
      },
      {
        id: '15.2',
        categoryName: 'Workshops',
        categoryDetail: 'Hands-on sessions, DIY, crafts.'
      },
      {
        id: '15.3',
        categoryName: 'Events & Shows',
        categoryDetail: 'Concerts, theater, live performances.'
      },
      {
        id: '15.4',
        categoryName: 'Travel & Tours',
        categoryDetail: 'Guided tours, vacation packages, cruises.'
      },
      {
        id: '15.5',
        categoryName: 'Adventure Experiences',
        categoryDetail: 'Skydiving, diving, wilderness treks.'
      },
      {
        id: '15.6',
        categoryName: 'Art & Culture',
        categoryDetail: 'Museum tickets, gallery openings, cultural festivals.'
      },
      {
        id: '15.7',
        categoryName: 'Wellness & Fitness',
        categoryDetail: 'Yoga classes, meditation retreats, spa days.'
      },
      {
        id: '15.8',
        categoryName: 'Food & Dining',
        categoryDetail: 'Tasting sessions, cooking classes, winery tours.'
      },
      {
        id: '15.9',
        categoryName: 'Sports Events',
        categoryDetail: 'Game tickets, tournaments, races.'
      },
      {
        id: '15.10',
        categoryName: 'Kids & Family Activities',
        categoryDetail: 'Zoo visits, family workshops, fun parks.'
      },
      {
        id: '15.11',
        categoryName: 'Nature & Wildlife',
        categoryDetail: 'Safaris, bird-watching tours, nature hikes.'
      },
      {
        id: '15.12',
        categoryName: 'Networking & Conferences',
        categoryDetail:
          'Business networking, trade shows, academic conferences.'
      },
      {
        id: '15.13',
        categoryName: 'Party & Celebration',
        categoryDetail: 'Party planning, weddings, special celebrations.'
      },
      {
        id: '15.14',
        categoryName: 'DIY & Crafts',
        categoryDetail: 'Crafting workshops, DIY home improvement sessions.'
      },
      {
        id: '15.15',
        categoryName: 'Digital Content & Downloads',
        categoryDetail: 'Ebooks, downloadable software, templates.'
      }
    ]
  }
];

export const levelThree = [
  {
    '1.1.1': {
      categoryName: 'Smartphones',
      categoryDetail:
        'Variety of smartphones from different brands, models, and operating systems.'
    },
    '1.1.2': {
      categoryName: 'Phone Cases',
      categoryDetail:
        'Protective covers, sleeves, wallet cases, rugged cases, etc.'
    },
    '1.1.3': {
      categoryName: 'Screen Protectors',
      categoryDetail:
        'Tempered glass, film protectors, privacy screens, anti-glare protectors.'
    },
    '1.1.4': {
      categoryName: 'Charging Accessories',
      categoryDetail:
        'Wall chargers, car chargers, wireless charging pads, charging cables.'
    },
    '1.1.5': {
      categoryName: 'Headphones & Earbuds',
      categoryDetail:
        'Wired headphones, Bluetooth earbuds, noise-cancelling headphones.'
    },
    '1.1.6': {
      categoryName: 'Mounts & Stands',
      categoryDetail: 'Car mounts, desk stands, ring holders, pop sockets.'
    }
  },
  {
    '11.1.1': {
      categoryName: 'Fresh Fruits',
      categoryDetail:
        'Apples, bananas, berries, tropical fruits, citrus fruits, etc.'
    },
    '11.1.2': {
      categoryName: 'Fresh Vegetables',
      categoryDetail:
        'Leafy greens, root vegetables, cruciferous, marrow, allium, etc.'
    },
    '11.1.3': {
      categoryName: 'Dried Fruits',
      categoryDetail: 'Raisins, apricots, prunes, figs, dates, etc.'
    },
    '11.1.4': {
      categoryName: 'Frozen Fruits & Vegetables',
      categoryDetail: 'Berries, mixed vegetables, peas, corn, spinach, etc.'
    },
    '11.1.5': {
      categoryName: 'Canned Fruits',
      categoryDetail: 'Peaches, pears, pineapples, fruit cocktails, etc.'
    },
    '11.1.6': {
      categoryName: 'Canned Vegetables',
      categoryDetail: 'Tomatoes, beans, corn, peas, carrots, etc.'
    },
    '11.1.7': {
      categoryName: 'Organic Fruits & Vegetables',
      categoryDetail: 'Certified organic produce free from synthetic additives.'
    }
  }
];
