import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  Modal,
  Nav,
  //OverlayTrigger,
  //Tooltip,
  Dropdown,
  Button
} from 'react-bootstrap';
import Login from 'components/authentication/simple/Login';
import Registration from 'components/authentication/simple/Registration';
import { logout } from 'config/firebase';
//import AppContext from 'context/Context';

const LandingRightSideNavItem = ({ displayDashboardButton }) => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const navigate = useNavigate();

  const signOut = () => {
    logout();
    navigate('/logout');
  };

  // This is the code for the dark mode toggle
  /**
   * const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
   * 
   * <Nav.Item as={'li'}>
        <Nav.Link
          className="pe-2 theme-switch-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip
                style={{ position: 'fixed' }}
                id="ThemeColor"
                className="d-none d-lg-block"
              >
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <span>
              <div className="theme-switch-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="me-2"
                />
              </div>
              <p className="d-lg-none mb-0">
                {isDark ? 'Switch to light theme ' : 'Switch to dark theme'}
              </p>
            </span>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
   */

  return (
    <Nav navbar className="ms-auto">
      {!displayDashboardButton ? (
        <>
          <Dropdown>
            <Dropdown.Toggle
              as={Link}
              to="#!"
              className="nav-link fw-semi-bold"
            >
              Login
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-end dropdown-menu-card mt-0 dropdown-caret dropdown-caret-bg">
              <Card className="navbar-card-login shadow-none">
                <Card.Body className="fs--1 fw-normal p-4">
                  <Login />
                </Card.Body>
              </Card>
            </Dropdown.Menu>
          </Dropdown>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="#!"
              onClick={() => setShowRegistrationModal(!showRegistrationModal)}
            >
              Register
            </Nav.Link>
            <Modal
              show={showRegistrationModal}
              centered
              onHide={() => setShowRegistrationModal(false)}
            >
              <Modal.Body className="p-0">
                <Card>
                  <Card.Body className="fs--1 fw-normal p-4">
                    <Registration />
                  </Card.Body>
                </Card>
              </Modal.Body>
            </Modal>
          </Nav.Item>
        </>
      ) : (
        <>
          <Button
            variant="falcon-primary"
            onClick={() => navigate('/dashboard')}
            size="sm"
            className="me-2"
          >
            Go To Dashboard
          </Button>
          <Button variant="falcon-danger" onClick={() => signOut()} size="sm">
            Logout
          </Button>
        </>
      )}
    </Nav>
  );
};

export default LandingRightSideNavItem;

LandingRightSideNavItem.propTypes = {
  displayDashboardButton: PropTypes.bool
};
