import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
// import { Col, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

const AccountForm = ({ register, errors }) => {
  return (
    <>
      <WizardInput
        label="Supplier or Broker Company Name (Payable to)"
        disabled={false}
        name="name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('vendorNamePayableTo')
        }}
      />

      <WizardInput
        label="Master UPC (5 Digit)"
        disabled={false}
        name="masterUPC"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('master5DigitUPC')
        }}
      />
    </>
  );
};

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  user: PropTypes.object
};

export default AccountForm;
