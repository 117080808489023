/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { updateUserPassword, reauthUser } from 'config/firebase';

const ChangePassword = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [showReauthModal, setShowReauthModal] = useState(false);

  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    if (formData.newPassword !== formData.confirmPassword) {
      toast.error('Passwords do not match!', {
        theme: 'colored'
      });
      setSubmitting(false);
      return;
    }
    const result = await updateUserPassword(formData.newPassword);
    if (result.success) {
      toast.success('Password Updated Successfully!', {
        theme: 'colored'
      });
      setFormData({
        newPassword: '',
        confirmPassword: ''
      });
      setSubmitting(false);
    } else {
      if (result.error === 'auth/requires-recent-login') {
        toast.error('Please re-authenticate to change password', {
          theme: 'colored'
        });
        setShowReauthModal(true);
        setSubmitting(false);
        return;
      }
      if (result.error === 'auth/weak-password') {
        toast.error(
          'Password is too weak! Please ensure your password is sufficiently complex',
          {
            theme: 'colored'
          }
        );
        setSubmitting(false);
        return;
      }
    }
  };

  const ReauthModal = ({ showModal, setShowModal }) => {
    //const [showReauthModal, setShowReauthModal] = useState(showModal);
    const [reauthFormData, setReauthFormData] = useState({
      password: ''
    });

    const handleChange = e => {
      setReauthFormData({
        ...reauthFormData,
        [e.target.name]: e.target.value
      });
    };

    const handleSubmit = async e => {
      e.preventDefault();
      setSubmitting(true);
      const result = await reauthUser(reauthFormData.password);
      if (result.success) {
        setShowModal(false);
      } else {
        toast.error('Incorrect Password!', {
          theme: 'colored'
        });
      }
    };

    const handleCloseReauthModal = () => setShowModal(false);

    return (
      <Modal show={showReauthModal} onHide={handleCloseReauthModal} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Security Check</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>Enter Your Current Password</Form.Label>
              <Form.Control
                type="password"
                value={formData.password}
                name="password"
                onChange={handleChange}
              />
            </Form.Group>
            <Button className="w-100" type="submit" disabled={isSubmitting}>
              Authenticate
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <ReauthModal
        showModal={showReauthModal}
        setShowModal={setShowReauthModal}
      />
      <Card className="mb-3">
        <FalconCardHeader title="Change Password" />
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={formData.newPassword}
                name="newPassword"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                value={formData.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
              />
            </Form.Group>
            <Button className="w-100" type="submit" disabled={isSubmitting}>
              Update Password
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ChangePassword;
