// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Placeholder } from 'react-bootstrap';
import defaultProfilePic from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';

const ProfileDropdown = () => {
  const userContext = useContext(FirebaseAuthContext);
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={defaultProfilePic} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {userContext && userContext.details ? (
            <Dropdown.Item disabled>
              <div className="d-flex align-items-center">
                <Avatar
                  src={defaultProfilePic}
                  className="avatar-sm avatar-rounded"
                />
                <div className="flex-1 ms-2">
                  <h6 className="mb-0">{userContext.details.name}</h6>
                  <span className="text-small text-muted">
                    {userContext.details.email}
                  </span>
                </div>
              </div>
            </Dropdown.Item>
          ) : (
            <Placeholder animation="glow" className="mb-2">
              <Placeholder xs={6} />
            </Placeholder>
          )}
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/profile">
            Account & Profile
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;

/**
 * <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#!">Set status</Dropdown.Item>
 */
