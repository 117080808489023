/* eslint-disable react/prop-types */
import { AuthWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from 'config/firebase';

const Success = ({ reset, handleNext, getValues, user }) => {
  const { setStep, setUser } = useContext(AuthWizardContext);
  const [saveExecuting, setSaveExecuting] = useState(false);

  const [formValues, setFormValues] = useState(null);
  useEffect(() => {
    let isSubbed = true;
    if (isSubbed) setFormValues(getValues());
    return () => (isSubbed = false);
  }, [getValues]);

  async function submitNewSupplier(values, uid) {
    try {
      // update the onboarding status to PENDING
      const userRef = doc(db, 'users', uid);
      await updateDoc(userRef, {
        onBoarding: {
          currentStep: 5,
          onboardingStatus: 'PENDING',
          timestamp: {
            modified: serverTimestamp()
          },
          applicationData: values
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSave(values, uid) {
    setSaveExecuting(true);
    submitNewSupplier(values, uid).then(() => {
      setSaveExecuting(false);
    });
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (formValues) handleSave(formValues, user?.uid);
    }
    return () => (isSubscribed = false);
  }, [formValues]);

  const emptyData = () => {
    setStep(1);
    setUser({});
    reset();
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">
            {!saveExecuting
              ? 'Your Topicz TradeHub Account is now registered and is awaiting approval.'
              : 'Saving...'}
          </h4>
          <p className="text-muted">
            You will receive an email once your account has been approved. After
            your account is approved you'll be able to login and start using
            TradeHub. If you have any questions, please contact us at{' '}
            <a href="mailto:tradehub_support@topicz.com">Support</a>
          </p>
          <Button
            variant="falcon-danger"
            className="px-5 my-3 me-3"
            onClick={emptyData}
          >
            Start Over
          </Button>
          <Button
            variant="falcon-primary"
            className="px-5 my-3"
            onClick={handleNext}
          >
            Continue Registration
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired,
  handleNext: PropTypes.func,
  getValues: PropTypes.func
};

export default Success;
