import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ProductStorage = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Storage Details
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Storage Conditions</Form.Label>
              <Form.Select
                {...register(`storageConditions`)}
                size="sm"
                placeholder="Select"
              >
                <option value="">Select</option>
                <option value="Dry">Dry</option>
                <option value="Refrigerated">Refrigerated</option>
                <option value="Frozen">Frozen</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Special Storage Instructions:</Form.Label>
              <Form.Control
                as="textarea"
                {...register(`storageSpecialInstructions`)}
                size="sm"
                placeholder=""
                rows={3}
              />
              <Form.Control.Feedback type="invalid">
                {errors.storageSpecialInstructions?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductStorage;
