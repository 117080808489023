import React from 'react';
import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';

const ProductDeliveredCost = () => {
  const {
    register,
    setValue,
    // watch,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Delivered Cost
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3 mb-3">
          <Col xs="12">
            <Form.Group>
              <Form.Label>Currency:</Form.Label>
              <Form.Select {...register(`deliveredCostCurrency`)}>
                <option value="usd">USD</option>
                <option value="eur">EUR</option>
                <option value="gbp">GBP</option>
                <option value="cad">CAD</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="gx-2 gy-3">
          <Col xs="12">
            <InputGroup>
              <InputGroup.Text>
                Master:
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      style={{ position: 'fixed' }}
                      id="basePriceTooltip"
                    >
                      Regular Delivered Cost per Master Case
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-primary fs--2">
                    <FontAwesomeIcon icon="question-circle" />
                  </span>
                </OverlayTrigger>
              </InputGroup.Text>
              <Form.Control
                type="number"
                {...register('masterPrice', {
                  onChange: e => setValue('masterPrice', e.target.value)
                })}
                isInvalid={!!errors.masterPrice}
              />
              <Form.Control.Feedback type="invalid">
                {errors.masterPrice?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col xs="12">
            <InputGroup>
              <InputGroup.Text>
                Inner:
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      style={{ position: 'fixed' }}
                      id="basePriceTooltip"
                    >
                      Regular Delivered Cost per Inner Case
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-primary fs--2">
                    <FontAwesomeIcon icon="question-circle" />
                  </span>
                </OverlayTrigger>
              </InputGroup.Text>
              <Form.Control
                type="number"
                {...register('innerPrice', {
                  onChange: e => setValue('innerPrice', e.target.value)
                })}
                isInvalid={!!errors.innerPrice}
              />
              <Form.Control.Feedback type="invalid">
                {errors.innerPrice?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col xs="12">
            <InputGroup>
              <InputGroup.Text>
                Each:
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      style={{ position: 'fixed' }}
                      id="basePriceTooltip"
                    >
                      Regular Delivered Cost per Each Case
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-primary fs--2">
                    <FontAwesomeIcon icon="question-circle" />
                  </span>
                </OverlayTrigger>
              </InputGroup.Text>
              <Form.Control
                type="number"
                {...register('eachPrice', {
                  onChange: e => setValue('eachPrice', e.target.value)
                })}
                isInvalid={!!errors.eachPrice}
              />
              <Form.Control.Feedback type="invalid">
                {errors.eachPrice?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>

          <Col xs="12">
            <Form.Group controlId="freightPerMaster">
              <Form.Label>
                Freight per Master:
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      style={{ position: 'fixed' }}
                      id="freightPriceTooltip"
                    >
                      Cost of Freight per Master Case
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-primary fs--1">
                    <FontAwesomeIcon icon="question-circle" />
                  </span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="text"
                name="freightPerMaster"
                {...register('freightPerMaster')}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductDeliveredCost;
