import coverSrc from 'assets/img/generic/bg-2.jpg';
import avatar from 'assets/img/team/avatar.png';
import VerifiedBadge from 'components/common/VerifiedBadge';
import React, { useState, useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import ProfileBanner from '../ProfileBanner';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';

const Banner = () => {
  const userContext = useContext(FirebaseAuthContext);
  const [nameTitle, setNameTitle] = useState('');
  const [roleTitle, setRoleTitle] = useState('');
  const [locationTitle, setLocationTitle] = useState('');

  async function populateUserData() {
    try {
      const userDetails = userContext.details;
      setNameTitle(userDetails.name);
      setRoleTitle(userDetails.heading);
      setLocationTitle(userDetails.location);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    let isSubscribed = true;
    if (!userContext.details) return;
    if (isSubscribed) {
      populateUserData().then(() => {
        return () => (isSubscribed = false);
      });
    }
  }, [userContext]);

  return (
    <ProfileBanner>
      <ProfileBanner.Header avatar={avatar} coverSrc={coverSrc} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {nameTitle} <VerifiedBadge />
            </h4>
            <h5 className="fs-0 fw-normal">
              {roleTitle} <span className="text-muted mx-1">·</span>{' '}
            </h5>
            <p className="text-500">{locationTitle}</p>
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3"></Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
