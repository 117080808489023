/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import BasicECharts from 'components/common/BasicEChart';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import React from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Loading } from 'components/common/Loading';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const getOptions = data => {
  // get list of days from data array
  const days = [];
  data.forEach(day => {
    days.push(day.day);
  });
  // reverse the order of the days array
  days.reverse();

  // get list of sales from data array
  const sales = [];
  data.forEach(day => {
    sales.push(Number(day.total));
  });
  sales.reverse();
  return {
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      formatter: '{b0} : ${c0}',
      transitionDuration: 1,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1
    },
    xAxis: {
      type: 'category',
      data: days,
      boundaryGap: true,
      axisLine: { show: false },
      axisLabel: { show: true },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    series: [
      {
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          borderRadius: 15
        },
        barWidth: '10px',
        itemStyle: {
          borderRadius: 20,
          color: getColor('primary')
        },
        data: sales,
        z: 10
      }
    ],
    grid: { right: 5, left: 5, top: 0, bottom: 25 }
  };
};

const WeeklySales = ({
  salesData,
  width,
  amountClassName,
  weeklyAverage,
  lastWeeksTotal,
  loading
}) => {
  const weeklyTotal = salesData.reduce((acc, curr) => {
    return acc + Number(curr.total);
  }, 0);

  // calculate the percentage change from last week's total
  const percentageChange =
    ((weeklyTotal - lastWeeksTotal) / lastWeeksTotal) * 100;
  // calculate the percentage change from the weekly average
  const averagePercentageChange =
    ((weeklyTotal - weeklyAverage) / weeklyAverage) * 100;

  const generateChangePercentBadge = (type, percentage) => {
    let color = 'success';
    if (percentage < 0) color = 'danger';
    if (percentage === 0) color = 'info';
    if (percentage > 0 && percentage < 1) color = 'warning';
    if (percentage > 1) color = 'success';
    return (
      <SoftBadge pill bg={color} className="me-2 fs--2">
        {type} {percentage > 0 && '+'}
        {percentage.toFixed(2)}%
      </SoftBadge>
    );
  };

  return (
    <Card className="h-md-100" style={{ minHeight: '150px' }}>
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">
          Weekly Sales
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip style={{ position: 'fixed' }}>
                Calculated according to last week's sales & the weekly average
              </Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Card.Header>

      {!loading ? (
        <Card.Body as={Flex} alignItems="end" justifyContent="between">
          <div>
            <h2
              className={classNames(
                amountClassName,
                'mb-1 text-700 fw-normal lh-1'
              )}
            >
              ${weeklyTotal}
            </h2>
            <div className="d-flex align-items-center">
              {generateChangePercentBadge('Last Week', percentageChange)}

              {generateChangePercentBadge('Average', averagePercentageChange)}
            </div>
          </div>
          <BasicECharts
            echarts={echarts}
            options={getOptions(salesData)}
            style={{ width: width || '8.5rem', height: 160 }}
          />
        </Card.Body>
      ) : (
        <Loading />
      )}
    </Card>
  );
};

export default WeeklySales;
