import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
// import PropTypes from 'prop-types';
import { Col, Row, Table } from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
// import { getPercentage } from 'helpers/utils';

const OrderedProducts = ({ order }) => {
  const orderedProductsData = order?.items;

  const subtotal = orderedProductsData?.reduce(
    (acc, curr) => curr.quantity * curr.unitPrice + acc,
    0
  );

  const tax = order?.taxAmount || 0; // getPercentage(subtotal, 5);

  return (
    <div className="table-responsive fs--1">
      <Table striped className="border-bottom">
        <thead className="bg-200 text-900">
          <tr>
            <th className="border-0">Products</th>
            <th className="border-0 text-center">Quantity</th>
            <th className="border-0 text-end">Rate</th>
            <th className="border-0 text-end">Amount</th>
          </tr>
        </thead>
        <tbody>
          {orderedProductsData?.map(product => (
            <tr key={product.orderItemId} className="border-200">
              <td className="align-middle">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {product?.unitPrice && product?.unitPrice < 0 && (
                    <SoftBadge pill bg="danger" className="me-2">
                      Discount Code
                    </SoftBadge>
                  )}
                  <h6 className="mb-0 text-nowrap">{product.name}</h6>
                  <p className="mb-0">{product.desc}</p>
                </div>
              </td>
              <td className="align-middle text-center">{product.quantity}</td>
              <td className="align-middle text-end">
                $
                {parseFloat(product.unitPrice).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </td>
              <td className="align-middle text-end">
                $
                {parseFloat(
                  product.quantity * product.unitPrice
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row className="g-0 justify-content-end">
        <Col xs="auto">
          <Table borderless size="sm" className="fs--1 text-end">
            <tbody>
              <tr>
                <th className="text-900">Subtotal:</th>
                <td className="fw-semi-bold">
                  $
                  {parseFloat(subtotal).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </td>
              </tr>
              <tr>
                <th className="text-900">Tax:</th>
                <td className="fw-semi-bold">
                  $
                  {parseFloat(tax).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </td>
              </tr>
              <tr className="border-top">
                <th className="text-900">Total:</th>
                <td className="fw-semi-bold">
                  $
                  {parseFloat(subtotal + tax).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

OrderedProducts.propTypes = {
  order: {}
};

export default OrderedProducts;
