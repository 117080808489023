import React from 'react';
import PropTypes from 'prop-types';
import WizardLayout from './OnboardingWizardLayout';
import AuthWizardProvider from './AuthWizardProvider';

const Wizard = ({ variant, validation, progressBar, userData, handleNext }) => {
  return (
    <AuthWizardProvider>
      <WizardLayout
        variant={variant}
        validation={validation}
        progressBar={progressBar}
        userData={userData}
        handleNext={handleNext}
      />
    </AuthWizardProvider>
  );
};

Wizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool,
  userData: PropTypes.object,
  handleNext: PropTypes.func
};

export default Wizard;
