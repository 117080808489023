import React, { useState } from 'react';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/navbar-vertical.png';
import { useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';
import { progressUserOnboarding } from 'config/firebase';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, storage } from 'config/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';

const UploadW9Step = ({ setStep }) => {
  const [authLoading, user] = useOutletContext();
  const [W9Submitted, setW9Submitted] = useState(false);

  const handleNext = async () => {
    await progressUserOnboarding(user.uid, 5);
    setStep(5);
  };

  const W9Advice = [
    {
      title: 'Payment Reporting',
      description:
        'Companies need to report payments made to suppliers and brokers to the IRS. The W-9 provides necessary details for accurate reporting.'
    },
    {
      title: 'Backup Withholding',
      description:
        'The W-9 helps determine if the supplier or broker is subject to backup withholding, ensuring taxes are collected if needed.'
    },
    {
      title: 'Form 1099 Reporting',
      description:
        'For payments of $600 or more in a year, a Form 1099-MISC must be issued. The W-9 data is used for this purpose.'
    },
    {
      title: 'Taxpayer Identification',
      description:
        "The W-9 provides the supplier's or broker's taxpayer identification number (TIN) for tax purposes."
    },
    {
      title: 'Avoiding Penalties',
      description:
        "Companies may face penalties if they don't collect W-9 forms from suppliers and brokers and can't provide proper documentation during IRS audits."
    }
  ];

  function W9Upload() {
    const BlankW9 =
      'https://firebasestorage.googleapis.com/v0/b/stk-merlin.appspot.com/o/w9%2FtopiczW9.pdf?alt=media&token=ed0552b5-ae18-43bb-96f3-f62226c8dbaf';
    const [file, setFile] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [url, setURL] = useState('');
    const [downloadClick, setDownloadClick] = useState(false);
    const [progress, setProgress] = useState(0);

    console.log(progress);

    const handleChange = e => {
      if (e.target.files[0]) {
        setFile(e.target.files[0]);
      }
    };

    const handleUpload = () => {
      if (file == null) {
        toast.error('Please select a file to upload!', {
          theme: 'colored'
        });
        return;
      }
      const storageRef = ref(storage, `completedW9s/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        snapshot => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        error => {
          // Error function ...
          console.log(error);
        },
        async () => {
          // complete function ...
          await getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            setURL(downloadURL);
            const userRef = doc(db, 'users', user.uid);
            updateDoc(userRef, {
              onBoarding: {
                w9file: downloadURL,
                w9Completed: true
              },
              timestamp: {
                modified: serverTimestamp()
              }
            })
              .then(() => {
                setW9Submitted(true);
                toast.success('W9 Uploaded Successfully!', {
                  theme: 'colored'
                });
              })
              .catch(error => {
                console.log(error);
              });
          });
        }
      );
    };

    return (
      <div>
        <Row>
          <Col>
            <h6>Complete & Upload W9</h6>
          </Col>
        </Row>
        {downloadClick == false ? (
          <Row>
            <Col>
              <a href={BlankW9} target="_blank" rel="noreferrer">
                <Button
                  variant="falcon-primary"
                  onClick={() => setDownloadClick(true)}
                >
                  Download Blank W9
                </Button>
              </a>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col width={2}>
              <Form.Group
                controlId="formFile"
                className="mb-3"
                onChange={handleChange}
              >
                <Form.Label>Select Completed W9</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
              <Button variant="falcon-primary" onClick={handleUpload}>
                Upload W9
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  return (
    !authLoading &&
    user && (
      <Card>
        <Card.Body className="overflow-hidden p-lg-6 pt-3 mt-3">
          <Row className="align-items-center justify-content-between">
            <Col lg={6}>
              <img src={editing} className="img-fluid" alt="" />
            </Col>
            <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
              <h3 className="text-primary mb-3">Why a W9?</h3>
              <div className="mb-4">
                {W9Advice.map((item, index) => (
                  <div key={index} className="mb-3">
                    <h5 className="text-primary">{item.title}</h5>
                    <p className="mb-0">{item.description}</p>
                  </div>
                ))}
              </div>
              <div className="g-3">{!W9Submitted && <W9Upload />}</div>

              <Button
                variant="falcon-primary"
                onClick={() => handleNext()}
                disabled={!W9Submitted}
              >
                Continue!
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  );
};

export default UploadW9Step;

UploadW9Step.propTypes = {
  setStep: PropTypes.func.isRequired
};
