/* eslint-disable react/prop-types */
import ecomBg from 'assets/img/illustrations/ecommerce-bg.png';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import Notification from './Notification';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { useContext } from 'react';

function chooseSalutation() {
  const date = new Date();
  const hour = date.getHours();
  if (hour < 12) {
    return 'Good Morning';
  } else if (hour < 18) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
}

// Data below is used to populate the GreetingCard component
/**
 * <Flex className="py-3">
    <div className="pe-3">
      <p className="text-600 fs--1 fw-medium">Today's visit</p>
      <h4 className="text-800 mb-0">14,209</h4>
    </div>
    <div className="ps-3">
      <p className="text-600 fs--1">Today’s total sales</p>
      <h4 className="text-800 mb-0">$21,349.29</h4>
    </div>
  </Flex>
 */

const GreetingCard = ({ notifications, userData, todaysSales }) => {
  // eslint-disable-next-line no-unused-vars
  const { authLoading, user } = useContext(FirebaseAuthContext);
  const salutation = chooseSalutation();

  return (
    <Card className="bg-transparent-50 overflow-hidden mb-3">
      <Card.Header className="position-relative">
        <div className="position-relative z-index-2">
          <div>
            {!authLoading && (
              <h3 className="text-primary mb-1">
                {salutation}, {userData?.name}
              </h3>
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <Background
          image={ecomBg}
          className="d-none d-md-block bg-card z-index-1"
          style={{
            backgroundSize: '230px',
            backgroundPosition: 'right bottom',
            zIndex: '-1'
          }}
        />
        <ul className="mb-0 list-unstyled">
          {notifications.map((notification, index) => (
            <Notification
              key={notification.id}
              notification={notification}
              isLast={notifications.length - 1 === index}
            />
          ))}
        </ul>
        <Flex className="py-3">
          <div className="ps-3">
            <p className="text-600 fs--1">Today’s Sales</p>
            <h4 className="text-800 mb-0">${todaysSales}</h4>
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

GreetingCard.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  userData: PropTypes.object
};

export default GreetingCard;
