import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Collapse } from 'react-bootstrap';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';

const ProfileIntro = () => {
  const userContext = useContext(FirebaseAuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const [introText, setIntroText] = useState('');
  const [extendedText, setExtendedText] = useState('');

  async function populateIntroData() {
    try {
      const userDetails = userContext.details;
      const rawIntroText = userDetails.intro;

      // if rawIntroText is over 300 characters, split it into two parts
      if (rawIntroText.length > 300) {
        setIntroText(rawIntroText.slice(0, 300) + '...');
        setExtendedText(rawIntroText.slice(300));
      } else {
        setIntroText(rawIntroText);
      }

      //setIntroText(userDetails.intro);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    let isSubbed = true;
    if (!userContext.details) return;
    if (isSubbed) {
      populateIntroData()
        .then(() => {})
        .catch(error => {
          console.log(error);
        });
    }
    return () => (isSubbed = false);
  }, [userContext]);

  return (
    <Card className="mb-3">
      <Card.Body className="text-justify text-1000">
        <p>{introText}</p>
        {extendedText !== null ? (
          <Collapse in={collapsed}>
            <div id="collapse-text">{extendedText}</div>
          </Collapse>
        ) : null}
      </Card.Body>

      <Card.Footer className="bg-light p-0 border-top d-grid">
        <Button
          variant="link"
          // className="me-2 mb-1"
          onClick={() => setCollapsed(!collapsed)}
        >
          Show {collapsed ? 'less' : 'more'}
          <FontAwesomeIcon
            icon="chevron-down"
            className="ms-2 fs--2"
            transform={collapsed ? 'rotate-180' : ''}
          />
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default ProfileIntro;
