import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../../../wizard/WizardInput';
import { Col, Row, Form } from 'react-bootstrap';

const PaymentDetailsForm = ({ register, errors }) => {
  return (
    <>
      <WizardInput
        type="number"
        label="Vendor Tax ID#"
        name="taxId"
        placeholder="Please enter your Tax ID#"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('taxId')
        }}
      />

      <WizardInput
        type="text"
        label="Remit Payment Street Address"
        name="address"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('remitStreetAddress')
        }}
      />
      <Row className="g-2 mb-3">
        <Col>
          <WizardInput
            type="text"
            label="City"
            name="city"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('remitCity')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="select"
            label="State"
            name="state"
            placeholder="Select State"
            options={[
              { label: 'Alabama', value: 'AL' },
              { label: 'Alaska', value: 'AK' },
              { label: 'Arizona', value: 'AZ' },
              { label: 'Arkansas', value: 'AR' },
              { label: 'California', value: 'CA' },
              { label: 'Colorado', value: 'CO' },
              { label: 'Connecticut', value: 'CT' },
              { label: 'Delaware', value: 'DE' },
              { label: 'Florida', value: 'FL' },
              { label: 'Georgia', value: 'GA' },
              { label: 'Hawaii', value: 'HI' },
              { label: 'Idaho', value: 'ID' },
              { label: 'Illinois', value: 'IL' },
              { label: 'Indiana', value: 'IN' },
              { label: 'Iowa', value: 'IA' },
              { label: 'Kansas', value: 'KS' },
              { label: 'Kentucky', value: 'KY' },
              { label: 'Louisiana', value: 'LA' },
              { label: 'Maine', value: 'ME' },
              { label: 'Maryland', value: 'MD' },
              { label: 'Massachusetts', value: 'MA' },
              { label: 'Michigan', value: 'MI' },
              { label: 'Minnesota', value: 'MN' },
              { label: 'Mississippi', value: 'MS' },
              { label: 'Missouri', value: 'MO' },
              { label: 'Montana', value: 'MT' },
              { label: 'Nebraska', value: 'NE' },
              { label: 'Nevada', value: 'NV' },
              { label: 'New Hampshire', value: 'NH' },
              { label: 'New Jersey', value: 'NJ' },
              { label: 'New Mexico', value: 'NM' },
              { label: 'New York', value: 'NY' },
              { label: 'North Carolina', value: 'NC' },
              { label: 'North Dakota', value: 'ND' },
              { label: 'Ohio', value: 'OH' },
              { label: 'Oklahoma', value: 'OK' },
              { label: 'Oregon', value: 'OR' },
              { label: 'Pennsylvania', value: 'PA' },
              { label: 'Rhode Island', value: 'RI' },
              { label: 'South Carolina', value: 'SC' },
              { label: 'South Dakota', value: 'SD' },
              { label: 'Tennessee', value: 'TN' },
              { label: 'Texas', value: 'TX' },
              { label: 'Utah', value: 'UT' },
              { label: 'Vermont', value: 'VT' },
              { label: 'Virginia', value: 'VA' },
              { label: 'Washington', value: 'WA' },
              { label: 'West Virginia', value: 'WV' },
              { label: 'Wisconsin', value: 'WI' },
              { label: 'Wyoming', value: 'WY' }
            ]}
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('remitState')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Postal Code"
            name="postalCode"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('remitPostalCode')
            }}
          />
        </Col>
      </Row>
      <Row className="g-2 mb-3">
        <Col>
          <WizardInput
            type="text"
            label="Phone Number"
            name="phone1"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('phone1')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Fax Number"
            name="fax1"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('fax1')
            }}
          />
        </Col>
      </Row>
      <Form.Label className="mb-3">
        Banking Details for ACH/Wire Payments
      </Form.Label>
      <Row className="g-2 mb-3">
        <Col>
          <WizardInput
            type="text"
            label="Name on Account"
            name="accountName"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('accountName')
            }}
          />
        </Col>
      </Row>
      <Row className="g-2">
        <Col>
          <Row>
            <Col>
              <WizardInput
                type="text"
                label="Enter Routing/ABA Number"
                name="routingABA"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('routingABA')
                }}
              />
            </Col>
            <Col>
              <WizardInput
                type="text"
                label="Re-enter Routing/ABA Number"
                name="routingABACheck"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('routingABACheck')
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-2">
        <Col>
          <Row>
            <Col>
              <WizardInput
                type="text"
                label="Enter Account Number"
                name="achAccountNumber"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('achAccountNumber')
                }}
              />
            </Col>
            <Col>
              <WizardInput
                type="text"
                label="Re-enter Account Number"
                name="achAccountNumberCheck"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('achAccountNumberCheck')
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

PaymentDetailsForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default PaymentDetailsForm;
