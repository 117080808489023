/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Col, Form, Row, InputGroup, Dropdown } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ProductWeightsDimensions = () => {
  const {
    register,
    //setValue,
    formState: { errors }
  } = useFormContext();
  const [masterWeightUnit, setMasterWeightUnit] = React.useState('lbs');
  const [innerWeightUnit, setInnerWeightUnit] = React.useState('lbs');
  const [eachWeightUnit, setEachWeightUnit] = React.useState('oz');

  // const [formData, setFormData] = useState({
  //   releaseDate: null
  // });

  // const handleChange = (name, value) => {
  //   setFormData({
  //     ...formData,
  //     [name]: value
  //   });
  // };
  const WeightUnitDropdown = ({ unit, setUnit }) => {
    const handleSelect = unit => {
      setUnit(() => unit);
    };
    return (
      <Dropdown>
        <Dropdown.Toggle variant="outline-primary" size="sm">
          {unit}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleSelect('lbs')}>lbs</Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect('oz')}>oz</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h6" className="bg-light">
          Weights
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col xs="12">
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Text>Master Case Weight</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="masterCaseWeight"
                  {...register('masterCaseWeight')}
                  size="sm"
                />
                <WeightUnitDropdown
                  unit={masterWeightUnit}
                  setUnit={setMasterWeightUnit}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.masterCaseWeight?.message}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Text>Inner Pack Weight</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="innerPackWeight"
                  {...register('innerPackWeight')}
                />
                <WeightUnitDropdown
                  unit={innerWeightUnit}
                  setUnit={setInnerWeightUnit}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.innerPackWeight?.message}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Text>Each Weight</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="eachWeight"
                  {...register('eachWeight')}
                />
                <WeightUnitDropdown
                  unit={eachWeightUnit}
                  setUnit={setEachWeightUnit}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.eachWeight?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header as="h6" className="bg-light">
          Dimensions (in inches)
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col xs="12">
              <Form.Label>Master Case</Form.Label>
              <Row>
                <Col>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Height</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="masterCaseHeight"
                      {...register('masterCaseHeight')}
                      size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.masterCaseHeight?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Width</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="masterCaseWidth"
                      {...register('masterCaseWidth')}
                      size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.masterCaseWidth?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Depth</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="masterCaseDepth"
                      {...register('masterCaseDepth')}
                      size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.masterCaseDepth?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              <Form.Label>Inner Case</Form.Label>
              <Row>
                <Col>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Height</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="innerCaseHeight"
                      {...register('innerCaseHeight')}
                      size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.innerCaseHeight?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Width</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="innerCaseWidth"
                      {...register('innerCaseWidth')}
                      size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.innerCaseWidth?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Depth</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="innerCaseDepth"
                      {...register('innerCaseDepth')}
                      size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.innerCaseDepth?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              <Form.Label>Each Case</Form.Label>
              <Row>
                <Col>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Height</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="eachCaseHeight"
                      {...register('eachCaseHeight')}
                      size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.eachCaseHeight?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Width</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="eachCaseWidth"
                      {...register('eachCaseWidth')}
                      size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.eachCaseWidth?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Depth</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="eachCaseDepth"
                      {...register('eachCaseDepth')}
                      size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.eachCaseDepth?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProductWeightsDimensions;
