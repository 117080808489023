import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Placeholder } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from 'config/firebase';
import { toast } from 'react-toastify';

const ProfileSettings = () => {
  const userContext = useContext(FirebaseAuthContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isPopulating, setPopulating] = useState(true);

  async function populateUserData() {
    try {
      const userDetails = userContext.details;
      setFormData({
        name: userDetails.name,
        email: userDetails.email,
        phone: userDetails.phone,
        heading: userDetails.heading,
        intro: userDetails.intro,
        location: userDetails.location
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    let isSubbed = true;
    if (!userContext.details) return;
    if (isSubbed) {
      populateUserData()
        .then(() => {
          setPopulating(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
    return () => (isSubbed = false);
  }, [userContext]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    heading: '',
    intro: '',
    location: ''
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    await updateProfile();
    setSubmitting(false);
  };

  async function updateProfile() {
    const userRef = doc(db, 'users', userContext.user.uid);
    return updateDoc(userRef, {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      heading: formData.heading,
      intro: formData.intro,
      location: formData.location,
      timestamp: {
        modified: serverTimestamp()
      }
    })
      .then(() => {
        toast.success('Profile Updated Successfully!', {
          theme: 'colored'
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <Card>
      <FalconCardHeader title="Profile Settings" />
      {isPopulating ? (
        <Card.Body className="bg-light">
          <div className="">
            <Placeholder as={Card.Title} animation="glow" className="mb-2">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} />
              <Placeholder xs={4} />
              <Placeholder xs={4} />
              <Placeholder xs={6} />
              <Placeholder xs={8} />
            </Placeholder>
            <Placeholder.Button variant="primary" xs={6} />
          </div>
        </Card.Body>
      ) : (
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Form.Group controlId="firstName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone"
                  value={formData.phone}
                  name="phone"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="heading">
              <Form.Label>Job Title or Role</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your job title or role"
                value={formData.heading}
                name="heading"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="heading">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Location"
                value={formData.location}
                name="location"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="intro">
              <Form.Label>Blurb</Form.Label>
              <Form.Control
                as="textarea"
                rows={13}
                placeholder="Share a little about yourself"
                value={formData.intro}
                name="intro"
                onChange={handleChange}
              />
            </Form.Group>
            <div className="text-end">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Update
              </Button>
            </div>
          </Form>
        </Card.Body>
      )}
    </Card>
  );
};

export default ProfileSettings;
