import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import { useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';
import { progressUserOnboarding } from 'config/firebase';

const WelcomeStep = ({ setStep }) => {
  const [authLoading, user] = useOutletContext();

  const handleNext = async () => {
    await progressUserOnboarding(user.uid, 2);
    setStep(2);
  };

  return (
    !authLoading &&
    user && (
      <Card>
        <Card.Body className="overflow-hidden p-lg-6 pt-3 mt-3">
          <Row className="align-items-center justify-content-between">
            <Col lg={6}>
              <img src={editing} className="img-fluid" alt="" />
            </Col>
            <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
              <h3 className="text-primary">Welcome {user.name}!</h3>
              <p className="lead">
                The next few pages will guide you through completing your
                TradeHub registration.{' '}
              </p>
              <Button variant="falcon-primary" onClick={() => handleNext()}>
                Let's Start!
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  );
};

export default WelcomeStep;

WelcomeStep.propTypes = {
  setStep: PropTypes.func.isRequired
};
