import React from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
import Partners from './Partners';
import Processes from './Processes';
import Services from './Services';
// import Testimonial from './Testimonial';
import Cta from './Cta';
import FooterStandard from './FooterStandard';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { useContext } from 'react';

const Landing = () => {
  const userContext = useContext(FirebaseAuthContext);
  return (
    <>
      <NavbarStandard
        authLoading={userContext.authLoading}
        user={userContext.user}
      />
      <Hero />
      <Partners />
      <Processes />
      <Services />
      <Cta />
      <FooterStandard />
    </>
  );
};

export default Landing;

/**
 * <Testimonial />
 */
