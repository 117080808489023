import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import Divider from 'components/common/Divider';
// import SocialAuthButtons from './SocialAuthButtons';
import { auth, logInWithEmailAndPassword } from 'config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const LoginForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (error) {
      toast.error(error.message, {
        theme: 'colored'
      });
      console.log(error);
    }
    if (user) navigate('/dashboard');
  }, [user, loading, navigate]);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    try {
      logInWithEmailAndPassword(formData.email, formData.password);
      toast.success(`Logged in as ${formData.email}`, {
        theme: 'colored'
      });
      navigate('/dashboard');
    } catch (error) {
      toast.error(error.message, {
        theme: 'colored'
      });
      console.log(error);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to={`/forgot-password`}>
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

// This is the code for Social Auth buttons
/**
 *  <Divider className="mt-4">or log in with</Divider>
    <SocialAuthButtons />
 */

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
