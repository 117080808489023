/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import { Link, useOutletContext, useNavigate } from 'react-router-dom';
// import { api } from 'config/firebase';
// import { useHttpsCallable } from 'react-firebase-hooks/functions';
// import { productData } from 'data/ecommerce/productData';
import { db } from 'config/firebase';
import { collection, query, where } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';

const ProductsTable = ({ products }) => {
  const columns = [
    {
      name: 'Name',
      accessor: 'name',
      sortable: true,
      minWidth: '250px',
      Cell: rowData => {
        const { name, docId } = rowData.row.original;
        return (
          <Link to={`/products/${docId}`}>
            <h5 className="mb-0 fs--1">{name}</h5>
          </Link>
        );
      }
    },
    {
      name: 'Price',
      accessor: 'price',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Category',
      accessor: 'category',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Stock',
      accessor: 'stock',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Actions',
      accessor: 'actions',
      sortable: true,
      minWidth: '150px'
    }
  ];

  function ProductAction({ selectedRowIds }) {
    const navigate = useNavigate();
    return (
      <Row className="flex-between-center mb-3">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {Object.keys(selectedRowIds).length > 0 ? (
              'You have selected ' +
              Object.keys(selectedRowIds).length +
              (Object.keys(selectedRowIds).length > 1
                ? ' products'
                : ' product')
            ) : (
              <span className="ms-1 h4 text-primary">Product Catalog</span>
            )}
          </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select size="sm" aria-label="Bulk actions">
                <option>Bulk Actions</option>
                <option value="refund">Refund</option>
                <option value="delete">Delete</option>
                <option value="archive">Archive</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                Apply
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                className="me-2"
                onClick={() => navigate('/products/new')}
              >
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </IconButton>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="external-link-alt"
                transform="shrink-3"
              >
                <span className="d-none d-sm-inline-block ms-1">Export</span>
              </IconButton>
            </div>
          )}
        </Col>
      </Row>
    );
  }

  ProductAction.propTypes = {
    selectedRowIds: PropTypes.object
  };

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={products}
      sortable
      pagination
      perPage={10}
      selection
      selectionColumnWidth={30}
    >
      <ProductAction table />
      <Row className="flex-end-center mb-3">
        <Col>
          <AdvanceTableSearchBox table />
        </Col>
      </Row>
      <AdvanceTable
        table
        headerClassName="bg-200 text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          bordered: true,
          striped: true,
          className: 'fs--2 mb-0 overflow-hidden'
        }}
      />
      <div className="mt-3">
        <AdvanceTableFooter
          rowCount={products?.length}
          table
          rowInfo
          navButtons
          rowsPerPageSelection
        />
      </div>
    </AdvanceTableWrapper>
  );
};

const Products = () => {
  const [user, authLoading, userData] = useOutletContext();
  const [products, setProducts] = useState([]);
  const [productsSnapshot, loading, error] = useCollection(
    query(
      collection(db, 'products'),
      where('orgId', '==', userData?.orgId || '')
    )
  );
  useEffect(() => {
    if (authLoading) return;
    if (loading) return;
    if (error) return;
    if (!productsSnapshot) return;
    setProducts(
      productsSnapshot.docs.map(doc => ({
        docId: doc.id,
        docRef: doc.ref,
        ...doc.data()
      }))
    );
  }, [authLoading, loading, error, productsSnapshot]);

  return (
    <Card>
      <Card.Body className="">
        {!loading && <ProductsTable products={products} />}
      </Card.Body>
    </Card>
  );
};

export default Products;

ProductsTable.propTypes = {
  products: PropTypes.array
};
