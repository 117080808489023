/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Image, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { api } from 'config/firebase';
import { connectFunctionsEmulator } from 'firebase/functions';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';

connectFunctionsEmulator(api, 'localhost', 5001);

const Orders = () => {
  const [
    apiGetWandaInventoryLocations,
    apiLocationsLoading,
    apiLocationsError
  ] = useHttpsCallable(api, 'getWandaInventoryLocations');

  const [
    apiGetWandaInventoryAtLocation,
    apiInventoryLoading,
    apiInventoryError
  ] = useHttpsCallable(api, 'getWandaInventoryAtLocation');

  const [locations, setLocations] = useState([]);
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    if (apiLocationsLoading || apiInventoryLoading) return;
    if (apiLocationsError || apiInventoryError) {
      console.log(apiLocationsError, apiInventoryError);
      return;
    }
    apiGetWandaInventoryLocations({ orgId: 496330 })
      .then(result => {
        const locations = result?.data.locations;
        setLocations(locations);
        const inventory = [];
        const promises = [];
        for (const location of locations) {
          promises.push(
            apiGetWandaInventoryAtLocation({
              orgId: 496330,
              locationId: location.id,
              locationData: location
            })
          );
        }
        Promise.all(promises).then(results => {
          for (const result of results) {
            inventory.push(...result.data.inventory);
          }
          setInventory(inventory);
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col xs="auto">
            <h5 className="mb-0">Inventory</h5>
          </Col>
          <Col xs="auto">
            <Button variant="outline-primary" size="sm">
              <Link to="/products/add">Send Inventory</Link>
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <div className="m-2">
        {!apiLocationsLoading && <InventoryTable inventory={inventory} />}
      </div>
    </Card>
  );
};

const InventoryTable = ({ inventory }) => {
  console.log(inventory[0]);
  const columns = [
    {
      accessor: 'sku',
      Header: 'SKU'
    },
    {
      accessor: 'name',
      Header: 'Name'
    },
    {
      accessor: 'count',
      Header: 'Count'
    },
    {
      accessor: 'location.name',
      Header: 'Location',
      Cell: rowData => {
        const { location } = rowData.row.original;
        return (
          <div>
            <div className="fs--2">
              {location.shortCode} - {location.address.state}
            </div>
          </div>
        );
      }
    }
  ];

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={inventory}
      sortable
      pagination
      perPage={10}
    >
      <Row className="mb-1">
        <Col xs={12}>
          <AdvanceTableSearchBox table />
        </Col>
      </Row>
      <AdvanceTable
        table
        headerClassName="bg-200 text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          bordered: true,
          striped: true,
          className: 'fs--1 mb-0'
        }}
      />
      <div className="mt-3">
        <AdvanceTableFooter
          rowCount={inventory?.length}
          table
          rowInfo
          navButtons
          rowsPerPageSelection
        />
      </div>
    </AdvanceTableWrapper>
  );
};

export default Orders;
