/* eslint-disable react/prop-types */
import React from 'react';
import { Typewriter } from 'react-simple-typewriter';

// messages is an array of "loading _________" messages
// to display to the user
export const Loading = ({ messages }) => {
  const defaultWords = ['insights', 'data', 'integrations'];
  const words = messages ? messages : defaultWords;

  return (
    <div className="position-absolute bottom-0 start-0">
      <h6 className="ms-3 text-primary fw-light">
        Loading{' '}
        <span className="fw-bold">
          <Typewriter
            words={words}
            loop={false}
            cursor
            cursorStyle="|"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </span>
      </h6>
    </div>
  );
};
