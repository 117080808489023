export default [
  {
    media: { icon: ['fab', 'sass'], color: 'info', className: 'fs-4' },
    title: 'Cloud Based Product Information Management',
    description:
      "TradeHub's cloud based product information management system allows you to easily list your products with AI enhanced data. "
  },
  {
    media: { icon: ['fab', 'node-js'], color: 'success', className: 'fs-5' },
    title: 'Inventory Management and Analytics',
    description:
      "Use TradeHub's advanced inventory management, purchase ordering and invoicing capabilities to simplify your supplier and/or broker business."
  },
  {
    media: {
      icon: ['fas', 'store'],
      color: 'danger',
      className: 'fs-6'
    },
    title: 'Simple E-Commerce',
    description:
      'TradeHub can help you create a simple online store to sell your products. You can also integrate with your existing online store and list your products across multiple channels, including your own online store, Amazon, eBay, and more.'
  }
];
