import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ProductMasterCase = () => {
  const [guaranteedState, setGuranteedState] = React.useState(true);
  const {
    register,
    formState: { errors }
  } = useFormContext();
  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Master Case & Pack Information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Master Case Pack:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.masterCasePack}
                {...register('masterCasePack')}
              />
              <Form.Label>
                <small className="text-muted">
                  Example: "12 Boxes to a Master Case" or "4/5#". Enter the
                  number of units in the master case pack. If sold individually,
                  enter 1.
                </small>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.masterCasePack?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Inner Pack:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.innerPack}
                {...register('innerPack')}
              />
              <Form.Label>
                <small className="text-muted">
                  Example: "Boxes/packages per master". Enter the number of
                  inner units in the master case pack. If sold individually,
                  enter 1.
                </small>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.innerPack?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Each:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.eachPack}
                {...register('eachPack')}
              />
              <Form.Label>
                <small className="text-muted">
                  Example: "2ct, 1pkg, oz". Enter the size of each retail unit.
                </small>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.innerPack?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Product Guaranteed?</Form.Label>
              <Form.Check
                type="switch"
                id="productGuaranteed"
                label={guaranteedState ? 'Yes' : 'No'}
                {...register('productGuaranteed')}
                onChange={() => setGuranteedState(!guaranteedState)}
                defaultChecked={guaranteedState}
              />
              <Form.Label>
                {!guaranteedState ? (
                  <small className="text-muted">
                    Please note that if you select "No", your product will not
                    be eligible for the 100% Satisfaction Guarantee. Guaranteed
                    products are more likely to be approved for sale.
                  </small>
                ) : null}
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductMasterCase;
