import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ProductShelfDetails = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Shelf Life & Expiration
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Shelf Life (Days)</Form.Label>
              <Form.Control
                type="text"
                {...register(`shelfLife`)}
                size="sm"
                placeholder="Enter Shelf Life in Days"
              />
              <Form.Control.Feedback type="invalid">
                {errors.shelfLife?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Julian/MFG/Expiry Date</Form.Label>
              <Form.Control
                type="text"
                {...register(`julianMfgExpiryDate`)}
                size="sm"
                placeholder=""
              />
              <Form.Control.Feedback type="invalid">
                {errors.julianMfgExpiryDate?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>How to Read:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...register(`howToReadExpiry`)}
                size="sm"
                placeholder="Describe how to read given date"
              />
              <Form.Control.Feedback type="invalid">
                {errors.howToReadExpiry?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductShelfDetails;
