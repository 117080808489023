/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import { Loading } from 'components/common/Loading';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = data => {
  // create a list of strings such as 'Week 1', 'Week 2', based on length of data array
  const weeks = [];
  for (let i = 0; i < data.length; i++) {
    weeks.push(`Week ${i + 1}`);
  }
  return {
    tooltip: {
      trigger: 'axis',
      formatter: '<strong>{b0}</strong> : {c0}'
    },
    xAxis: {
      data: weeks
    },
    series: [
      {
        type: 'line',
        data,
        smooth: true,
        lineStyle: {
          width: 3
        },

        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.25)
              },
              {
                offset: 1,
                color: rgbaColor(getColor('primary'), 0)
              }
            ]
          }
        }
      }
    ],
    grid: {
      bottom: '2%',
      top: '2%',
      right: '0px',
      left: '10px'
    }
  };
};

const calculateAverageGrowthRate = array => {
  if (array.length < 2) return 0;

  const startValue = array[0].total;
  const endValue = array[array.length - 1].total;
  const periods = array.length - 1;

  return Math.pow(endValue / startValue, 1 / periods) - 1;
};

const TotalOrder = ({ data, loading }) => {
  // create a list of total sales from data array
  const sales = [];
  data.forEach(day => {
    sales.push(day.sales);
  });

  const totalSales = sales.reduce((acc, curr) => {
    return acc + Number(curr);
  }, 0);
  const avgGrowth = calculateAverageGrowthRate(data);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Total Orders</h6>
      </Card.Header>

      {!loading ? (
        <Card.Body
          as={Flex}
          alignItems="end"
          justifyContent="between"
          className="pt-0"
        >
          <div>
            <h2 className="fw-normal text-700 mb-1 lh-1">{totalSales}</h2>
            <Badge pill bg="200" className="text-primary fs--2">
              <FontAwesomeIcon icon="caret-up" className="me-1" />
              {Number(avgGrowth * 100).toFixed(2)}%
            </Badge>
          </div>
          <div className="">
            <BasicECharts
              echarts={echarts}
              options={getOptions(sales)}
              style={{ width: '400px', height: 200 }}
            />
          </div>
        </Card.Body>
      ) : (
        <Loading />
      )}
    </Card>
  );
};

TotalOrder.propTypes = { data: PropTypes.array.isRequired };

export default TotalOrder;
