import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext, useFieldArray } from 'react-hook-form';

const ProductShipperSpecs = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shipperContents'
  });
  const [specification, setSpecification] = useState({
    description: '',
    quantity: '',
    oz: '',
    srp: '',
    upc: ''
  });

  return (
    <>
      <span className="h6">Contents in Shipper</span>
      <div>
        {fields.length > 0 ? (
          <table className="w-100 mb-3">
            <thead>
              <tr>
                <th>Description</th>
                <th>Quantity</th>
                <th>Oz</th>
                <th>SRP</th>
                <th>UPC</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((content, index) => {
                /** Create a table with the following headers:
                 *   - Description
                 *   - Quantity
                 *   - Oz
                 *   - SRP
                 *   - UPC
                 */
                return (
                  <tr key={index}>
                    <td>{content.description}</td>
                    <td>{content.quantity}</td>
                    <td>{content.oz}</td>
                    <td>{content.srp}</td>
                    <td>{content.upc}</td>
                    <td>
                      <Button
                        variant="link"
                        type="button"
                        className="text-danger"
                        size="sm"
                        onClick={() => remove(index)}
                      >
                        <FontAwesomeIcon className="fs--1" icon="trash-alt" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="mt-3">Add item content to the shipper below.</p>
        )}

        <Row className="mt-3 gy-3 gx-2">
          <Col sm={3}>
            <Form.Control
              type="text"
              size="sm"
              name="specificationDescription"
              placeholder="Description"
              value={specification.description}
              onChange={e =>
                setSpecification({
                  ...specification,
                  description: e.target.value
                })
              }
            />
          </Col>
          <Col sm={1}>
            <Form.Control
              type="text"
              size="sm"
              name="specificationQuantity"
              placeholder="Quantity"
              value={specification.quantity}
              onChange={e =>
                setSpecification({
                  ...specification,
                  quantity: e.target.value
                })
              }
            />
          </Col>
          <Col sm={1}>
            <Form.Control
              type="text"
              size="sm"
              name="specificationOunces"
              placeholder="Oz"
              value={specification.oz}
              onChange={e =>
                setSpecification({
                  ...specification,
                  oz: e.target.value
                })
              }
            />
          </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              size="sm"
              name="specificationSRP"
              placeholder="SRP"
              value={specification.srp}
              onChange={e =>
                setSpecification({
                  ...specification,
                  srp: e.target.value
                })
              }
            />
          </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              size="sm"
              name="specificationUpc"
              placeholder="UPC"
              value={specification.upc}
              onChange={e =>
                setSpecification({
                  ...specification,
                  upc: e.target.value
                })
              }
            />
          </Col>
          <Col sm={1}>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                append(specification);
                setSpecification({
                  description: '',
                  quantity: '',
                  oz: '',
                  srp: '',
                  upc: ''
                });
              }}
            >
              <FontAwesomeIcon icon="plus" className="fs--2" />
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProductShipperSpecs;
