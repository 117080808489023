import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import { useOutletContext, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'config/firebase';

// This copmonent is used to display the pending status of the onboarding process
const Pending = () => {
  const [authLoading, user] = useOutletContext();
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();

  async function getCurrentStatus(uid) {
    if (!uid) return;
    //const navigate = useNavigate();
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    const user = userDoc.data();
    const status = user.onBoarding.status;
    setStatus(status);
  }

  useEffect(() => {
    let isSubscribed = true;
    if (authLoading) return;
    if (!user) return;
    if (isSubscribed) getCurrentStatus(user.uid);
    return () => (isSubscribed = false);
  }, [authLoading, user]);

  return !authLoading && user && status === 'COMPLETE' ? (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6 pt-3 mt-3">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <img src={editing} className="img-fluid" alt="" />
          </Col>
          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
            <h3 className="text-primary">Welcome to TradeHub!</h3>
            <p className="lead">
              Thank you! Your application has been approved!
            </p>
            <Button
              variant="primary"
              className="btn-lg btn-icon btn-icon-start ms-0"
              onClick={() => navigate('/dashboard')}
            >
              <span className="btn-inner--text">Go to Dashboard</span>
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  ) : (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6 pt-3 mt-3">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <img src={editing} className="img-fluid" alt="" />
          </Col>
          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
            <h3 className="text-primary">Approval is Pending</h3>
            <p className="lead">
              Thank you! Please wait while we review your application.
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Pending;

Pending.propTypes = {
  setStep: PropTypes.func
};
