import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ProductTiersLayers = () => {
  const { register } = useFormContext();
  const [layersOrPallets, setLayersOrPallets] = React.useState('Layers');

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Tiers & Layers / Pallets
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col>
            <Form.Group>
              <Form.Label>Order by:</Form.Label>
              <Form.Select
                {...register(`orderByLayersOrPallets`)}
                size="sm"
                onChange={e => setLayersOrPallets(() => e.target.value)}
              >
                <option value="Layers">Layers</option>
                <option value="Pallets">Pallets</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className="d-flex align-items-end">
            {layersOrPallets === 'Layers' ? (
              <div>
                <Col xs="12">
                  <Form.Control
                    type="text"
                    {...register(`layersTiers`)}
                    size="sm"
                    placeholder="Enter Ti (Tiers)"
                  />
                </Col>
                <Col xs="12">
                  <Form.Control
                    type="text"
                    {...register(`layersHigh`)}
                    size="sm"
                    placeholder="Enter Hi (Height)"
                  />
                </Col>
              </div>
            ) : (
              <div>
                <Col xs="12">
                  <Form.Control
                    type="text"
                    {...register(`tiersLayers`)}
                    size="sm"
                    placeholder="Enter Pallets"
                  />
                </Col>
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductTiersLayers;
