import React, { useEffect, useState } from 'react';
import WeeklySales from './WeeklySales';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import TotalOrder from './TotalOrder';
import BestSellingProducts from './BestSellingProducts';
import GreetingCard from 'components/dashboards/e-commerce/greetings/GreetingCard';
// import { notifications } from 'data/dashboard/ecom';
import { useOutletContext, Link } from 'react-router-dom';
import { api } from 'config/firebase';
import { connectFunctionsEmulator } from 'firebase/functions';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
connectFunctionsEmulator(api, 'localhost', 5001);

const Dashboard = () => {
  // eslint-disable-next-line no-unused-vars
  const [user, authLoading, userData] = useOutletContext();
  const [apiGetWeeklySalesData, apiWeeklySalesloading, apiWeeklySalesError] =
    useHttpsCallable(api, 'getWandaWeeklySalesData');
  const [weeklySales, setWeeklySales] = useState([]);
  const [
    apiGetWeeklyOrderVolume,
    apiWeeklyOrderVolumeLoading,
    apiWeeklyOrderVolumeError
  ] = useHttpsCallable(api, 'getWandaWeeklyOrderVolume');
  const [weeklyOrderVolume, setWeeklyOrderVolume] = useState([]);
  const [weeklyAverage, setWeeklyAverage] = useState(0);
  const [lastWeeksTotal, setLastWeeksTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [
    apiGetWandaBestSellingProducts,
    apiBestSellerLoading,
    apiBestSellerError
  ] = useHttpsCallable(api, 'getWandaBestSellingProducts');
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [bestSellerPeriod, setBestSellerPeriod] = useState('week');
  const [todaysSales, setTodaysSales] = useState(0);

  useEffect(() => {
    if (
      apiWeeklySalesloading ||
      apiWeeklyOrderVolumeLoading ||
      apiBestSellerLoading
    ) {
      setLoading(true);
    }
    if (
      !apiWeeklySalesloading &&
      !apiWeeklyOrderVolumeLoading &&
      !apiBestSellerLoading
    ) {
      setLoading(false);
    }
  }, [apiWeeklySalesloading, apiWeeklyOrderVolumeLoading, apiBestSellerError]);

  useEffect(() => {
    if (apiBestSellerLoading) return;
    if (apiBestSellerError) {
      console.log(apiBestSellerError);
      return;
    }
    apiGetWandaBestSellingProducts({ orgId: 496330, period: bestSellerPeriod })
      .then(result => {
        if (result?.data?.products) {
          const bestProducts = result.data.products;
          // sort best products by totalSales
          bestProducts.sort((a, b) => {
            return b.totalSales - a.totalSales;
          });
          setBestSellingProducts(result.data.products);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [bestSellerPeriod]);

  useEffect(() => {
    if (
      apiWeeklySalesloading ||
      apiWeeklyOrderVolumeLoading ||
      apiBestSellerLoading
    )
      return;
    if (
      apiWeeklySalesError ||
      apiWeeklyOrderVolumeError ||
      apiBestSellerError
    ) {
      console.log(apiWeeklySalesError, apiWeeklyOrderVolumeError);
      return;
    }
    apiGetWeeklySalesData({ orgId: 496330 })
      .then(result => {
        if (result?.data) {
          const sales = result.data.salesData;
          const todaysSales = sales[sales.length - 1].total;
          setTodaysSales(todaysSales);
          setWeeklySales(result.data.salesData);
        }
      })
      .catch(err => {
        console.log(err);
      });
    apiGetWeeklyOrderVolume({ orgId: 496330 })
      .then(result => {
        if (result?.data?.orderVolumeData) {
          const data = result.data.orderVolumeData;
          setWeeklyOrderVolume(data);
          setWeeklyAverage(calculateWeeklySalesAverage(data));
          setLastWeeksTotal(data[data.length - 1].total);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  function calculateWeeklySalesAverage(orderVolumeData) {
    let total = 0;
    orderVolumeData.forEach(order => {
      total += order.total;
    });
    return total / orderVolumeData.length;
  }

  function handleSelectChange(e) {
    setBestSellerPeriod(e.target.value);
  }

  return (
    <>
      <GreetingCard
        notifications={[]}
        userData={userData}
        todaysSales={todaysSales}
      />
      <Row className="g-3 mb-3">
        <Col sm={6} xxl={3}>
          <WeeklySales
            loading={loading}
            salesData={weeklySales}
            lastWeeksTotal={lastWeeksTotal}
            weeklyAverage={weeklyAverage}
            width={300}
          />
        </Col>
        <Col sm={6} xxl={3} className="order-xxl-1">
          <TotalOrder
            data={weeklyOrderVolume}
            loading={apiWeeklyOrderVolumeLoading}
          />
        </Col>
        <Col xxl={6}>
          <Card
            className="h-lg-100 overflow-hidden"
            style={{ backgroundColor: '#FFFFFF' }}
          >
            <Card.Body
              className="bg-light p-0 "
              style={{ backgroundColor: '#FFFFFF' }}
            >
              <BestSellingProducts
                products={bestSellingProducts}
                loading={loading}
              />
            </Card.Body>
            <Card.Footer className="bg-light py-2">
              <Row className="flex-between-center">
                <Col xs="auto">
                  <Form.Select
                    size="sm"
                    className="me-2"
                    onChange={e => handleSelectChange(e)}
                  >
                    <option value={'week'}>Last 7 days</option>
                    <option value={'month'}>Last Month</option>
                    <option value={'year'}>Last Year</option>
                  </Form.Select>
                </Col>
                <Col xs="auto">
                  <Button variant="falcon-default" size="sm" as={Link} to="#!">
                    View All
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
    </>
  );
};

export default Dashboard;
