import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import ProductDetailsMedia from './ProductDetailsMedia';
import ProductDetailsMain from './ProductDetailsMain';
import ProductDetailsFooter from './ProductDetailsFooter';
import CartModal from '../../cart/CartModal';
import Flex from 'components/common/Flex';
import { useDocument } from 'react-firebase-hooks/firestore';
import { db } from 'config/firebase';
import { doc } from 'firebase/firestore';

const ProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [productSnapshot, loading, error] = useDocument(
    doc(db, 'products', productId)
  );
  useEffect(() => {
    let isSubscribed = true;
    if (loading) return;
    if (error) {
      console.log(error);
      return;
    }
    if (productSnapshot) {
      let temp = productSnapshot.data();
      temp.docId = productSnapshot.id;
      temp.docRef = productSnapshot.ref;
      if (isSubscribed) setProduct(temp);
    }
    return () => (isSubscribed = false);
  }, [productSnapshot, loading, error]);

  return product ? (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col lg={6} className="mb-4 mb-lg-0">
              <ProductDetailsMedia product={product} />
            </Col>
            <Col lg={6} as={Flex} justifyContent="between" direction="column">
              <ProductDetailsMain product={product} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ProductDetailsFooter product={product} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <CartModal />
    </>
  ) : (
    <span>Loading...</span>
  );
};

export default ProductDetails;
