/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import SoftBadge from 'components/common/SoftBadge';
import { api } from 'config/firebase';
import { connectFunctionsEmulator } from 'firebase/functions';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { Row, Col, Button, Table, Image } from 'react-bootstrap';
import { Loading } from 'components/common/Loading';

connectFunctionsEmulator(api, 'localhost', 5001);

const ShippingDetails = ({ order }) => {
  const [apiGetShippingDetails, apiLoading, apiError] = useHttpsCallable(
    api,
    'getWandaShippingDetails'
  );
  const [loadSuccess, setLoadSuccess] = useState(false);
  const [loadUserMessage, setLoadUserMessage] = useState('');
  const [shippingDetails, setShippingDetails] = useState([]);
  const [completeDateTimes, setCompleteDateTimes] = useState({});
  useEffect(() => {
    if (apiLoading) return;
    if (apiError) {
      console.log(apiError);
      return;
    }
    apiGetShippingDetails({ orgId: 496330, orderId: order.orderId })
      .then(result => {
        if (!result.data.success) {
          setLoadSuccess(false);
          setLoadUserMessage(result?.data.message);
          return;
        }
        const details = result?.data.fulfillment;
        setShippingDetails(details);
        const times = {
          pick: new Date(details?.pick?.completedAt._seconds * 1000),
          pack: new Date(details?.pack?.completedAt._seconds * 1000),
          diff:
            new Date(details?.pack?.completedAt._seconds * 1000) -
            new Date(details?.pick?.completedAt._seconds * 1000)
        };
        setCompleteDateTimes(times);
        setLoadSuccess(true);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const DisplayItemImages = order => {
    return (
      <>
        {order?.items?.map(item => {
          if (!item?.imageUrl) return;
          return (
            <div
              key={item?.orderItemId}
              style={{ width: '100', height: '300px', overflow: 'hidden' }}
            >
              <Image
                src={item?.imageUrl}
                alt={item?.name}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                className="rounded"
              />
            </div>
          );
        })}
      </>
    );
  };

  const DisplayPackImage = base64Img => {
    const handleClick = () => {
      const win = window.open();
      win.document.write('<img src="' + base64Img + '" />');
    };

    return (
      <>
        <div style={{ width: '100', height: '300px', overflow: 'hidden' }}>
          <Image
            src={base64Img}
            alt="pack"
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            className="rounded"
            onClick={handleClick}
          />
        </div>
      </>
    );
  };

  const OpenPDFButton = base64PDF => {
    const openPDFWindow = () => {
      const pdfWindow = window.open('');
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${base64PDF}'></iframe>`
      );
    };

    return (
      <Button
        className="mt-3"
        size="sm"
        variant="falcon-default"
        onClick={openPDFWindow}
      >
        View Shipping Label
      </Button>
    );
  };

  const DisplayStatus = (status, completeTime) => {
    let statusMsg = 'Unknown';
    let bg = 'info';
    switch (status) {
      case 'COMPLETED':
      case 'COMPLETE':
        statusMsg = 'Complete';
        break;
      case 'shipped':
        statusMsg = 'Shipped';
        bg = 'success';
        break;
      default:
        statusMsg = status;
        break;
    }
    return (
      <SoftBadge bg={bg} className="fs--2 d-block align-middle">
        <div className="mt-1">
          {statusMsg} - {completeTime}{' '}
        </div>
      </SoftBadge>
    );
  };

  return !apiLoading ? (
    <>
      {loadSuccess ? (
        <>
          <Row>
            <Col lg={4} className="mb-4 mb-lg-0">
              <h5 className="mb-3 fs-0">Pick</h5>
              <p className="mb-1 fs--1">
                {DisplayStatus(
                  shippingDetails?.pick?.status,
                  completeDateTimes?.pick?.toLocaleString()
                )}
              </p>
              <p className="mb-1 fs--1">{DisplayItemImages(order)}</p>
            </Col>
            <Col lg={4} className="mb-4 mb-lg-0">
              <h5 className="mb-3 fs-0">Pack</h5>
              <p className="mb-1 fs--1">
                {DisplayStatus(
                  shippingDetails?.pack?.status,
                  completeDateTimes?.pack?.toLocaleString()
                )}
              </p>
              <p className="mb-1 fs--1">
                {DisplayPackImage(shippingDetails?.pack?.packImage?.imageData)}
              </p>
            </Col>
            <Col lg={4}>
              <h5 className="mb-3 fs-0">Ship</h5>
              <p className="mb-1 fs--1">{DisplayStatus(order?.orderStatus)}</p>
              <Table borderless size="sm" className="fs--1 p-1">
                <tbody>
                  <tr>
                    <td>Processing Time</td>
                    <td>
                      {Number(completeDateTimes?.diff / 1000 / 60).toFixed(2)}{' '}
                      minutes
                    </td>
                  </tr>
                  <tr>
                    <td>Requested Service</td>
                    <td>{shippingDetails?.ship?.requestedService}</td>
                  </tr>
                  <tr>
                    <td>Selected Service</td>
                    <td>
                      {shippingDetails?.ship?.selectedService?.serviceName}
                    </td>
                  </tr>
                  <tr>
                    <td>Tracking Number</td>
                    <td>{shippingDetails?.ship?.label?.trackingNumber}</td>
                  </tr>
                  <tr>
                    <td>Label</td>
                    <td>{OpenPDFButton(shippingDetails?.ship?.label?.pdf)}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : (
        <p>{loadUserMessage}</p>
      )}
    </>
  ) : (
    <Loading
      messages={['Pick Job Data', 'Pack Job Status', 'Shipping Information']}
    />
  );
};

export default ShippingDetails;
