import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
// import editing from 'assets/img/icons/spot-illustrations/21.png';
import { useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';
import { progressUserOnboarding } from 'config/firebase';

const NewVendorInformationStep = ({ setStep }) => {
  const [authLoading, user] = useOutletContext();

  const handleNext = async () => {
    await progressUserOnboarding(user.uid, 3);
    setStep(3);
  };

  return (
    !authLoading &&
    user && (
      <Card>
        <Card.Body className="overflow-hidden p-lg-6 pt-3 mt-3">
          <Row className="align-items-center justify-content-between">
            <Col>
              <h3 className="text-primary">
                Welcome to TradeHub, {user.name}!
              </h3>
              <div className="">
                <br />
                We would like to welcome you as a new supplier to our company!
                In order to facilitate a smooth transition, we ask that you
                please complete the following steps to set up your account.
                <br />
                <br />
                <strong>Step 1:</strong> Complete the Vendor Information Wizard.
                <br />
                <strong>Step 2:</strong> Complete the W-9 Request for Taxpayer
                Indentification Number and Certification.
                <br />
                <strong>Step 3:</strong> Add New Products via the New Product
                Wizard or Bulk Upload.
                <br />
                <br />
                If you have any questions, please contact us at 1-800-555-5555.
                <br />
                <br />
                Thank you,
                <br />
                <br />
                <strong>Vendor Management Team</strong>
              </div>

              <Button
                variant="falcon-primary"
                onClick={() => handleNext()}
                className="mt-3"
              >
                Next Step
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  );
};

export default NewVendorInformationStep;

NewVendorInformationStep.propTypes = {
  setStep: PropTypes.func.isRequired
};
