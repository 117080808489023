/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
// import visa from '../../../../../assets/img/icons/visa.png';
import SoftBadge from 'components/common/SoftBadge';

const DisplayPaymentMethod = ({ paymentMethod }) => {
  let method;
  switch (paymentMethod) {
    case 'shopify_payments':
      method = 'Shopify Payments';
      break;
    case 'paypal':
      method = 'PayPal';
      break;
    case 'manual':
      method = 'Manual';
      break;
    default:
      method = 'Unknown';
      break;
  }
  return (
    <SoftBadge pill bg="info" className="fs--2 me-3 d-block align-middle">
      <div className="mt-2">{method}</div>
    </SoftBadge>
  );
};

const OrderInfo = ({ order }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Billing Address</h5>
            <h6 className="mb-2">{order?.billTo?.name}</h6>
            {order?.billTo?.street1 && (
              <p className="mb-1 fs--1">
                {order?.billTo.stree1} <br />
                {order?.billTo.street2 && (
                  <>
                    {order?.billTo.street2} <br />
                  </>
                )}
                {order?.billTo.street3 && (
                  <>
                    {order?.billTo.street3} <br />
                  </>
                )}
                {order?.billTo?.city && (
                  <>
                    {order?.billTo.city}, {order?.billTo.state}{' '}
                    {order?.billTo.postalCode}
                  </>
                )}
              </p>
            )}
            {order?.customerEmail && (
              <p className="mb-0 fs--1">
                <strong>
                  Email:{' '}
                  <a href={order?.customerEmail}>{order?.customerEmail}</a>
                </strong>
              </p>
            )}
            {order?.billTo?.phone && (
              <p className="mb-0 fs--1">
                <strong>
                  Phone:{' '}
                  <a href={`tel:${order.billTo.phone}`}>{order.billTo.phone}</a>
                </strong>
              </p>
            )}
          </Col>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Shipping Address</h5>
            {order?.shipTo?.name && (
              <h6 className="mb-2">{order.shipTo.name}</h6>
            )}
            {order?.shipTo?.street1 && (
              <p className="mb-1 fs--1">
                {order?.shipTo.street1} <br />
                {order?.shipTo.street2 && (
                  <>
                    {order?.shipTo.street2} <br />
                  </>
                )}
                {order?.shipTo.street3 && (
                  <>
                    {order?.shipTo.street3} <br />
                  </>
                )}
                {order?.shipTo?.city && (
                  <>
                    {order?.shipTo.city}, {order?.shipTo?.state}{' '}
                    {order?.shipTo?.postalCode}
                  </>
                )}
              </p>
            )}
            {order?.shipTo?.phone && (
              <p className="mb-0 fs--1">
                <strong>
                  Phone:{' '}
                  <a href={`tel:${order.shipTo.phone}`}>{order.shipTo.phone}</a>
                </strong>
              </p>
            )}
          </Col>
          <Col md={6} lg={4}>
            <h5 className="mb-3 fs-0">Payment Method</h5>
            <div className="d-flex">
              <DisplayPaymentMethod
                paymentMethod={order?.paymentMethod}
                className="me-3"
              />
              <div className="flex-1">
                <h6 className="mb-0">Payment Date</h6>
                <p className="mb-0 fs--1">
                  {new Date(order?.paymentDate).toDateString()}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

OrderInfo.propTypes = {};

export default OrderInfo;
