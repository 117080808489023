import bw1 from 'assets/img/partners/Campbells-2.png';
import bw2 from 'assets/img/partners/Hershey-500.png';
import bw3 from 'assets/img/partners/Kraft-Foods.png';
import bw4 from 'assets/img/partners/Mike-Sells-Logo.png';
import bw5 from 'assets/img/partners/Ocean-Spray.png';
import bw6 from 'assets/img/partners/Plnaters-500.png';
import bw7 from 'assets/img/partners/Prairie-500.png';

// busken-login-logo.png
// Campbells-2.png
// Hershey-500.png
// Kraft-Foods.png
// Mike-Sells-Logo.png
// Ocean-Spray.png
// Plnaters-500.png
// Prairie-500.png
// Pringles-1.png
// quaker-1.png
// velvet-500.png

export default [
  { src: bw1, height: 60 },
  { src: bw2, height: 65 },
  { src: bw3, height: 60 },
  { src: bw4, height: 60 },
  { src: bw5, height: 65 },
  { src: bw6, height: 60 },
  { src: bw7, height: 60 }
];
