import React, { createContext, useContext, useEffect, useState } from 'react';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from 'config/firebase';
import PropTypes from 'prop-types';
const UserContext = createContext();

export const useUserContext = () => {
  return useContext(UserContext);
};

// eslint-disable-next-line no-unused-vars
export const UserProvider = ({ children, value }) => {
  const { authLoading, user } = useContext(FirebaseAuthContext);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  async function checkStatus(uid) {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    const user = userDoc.data();
    setUserData(user);
  }

  useEffect(() => {
    let isSubscribed = true;
    if (authLoading) return;
    if (!user) navigate('/login');
    if (user) {
      if (isSubscribed) checkStatus(user.uid);
    }
    return () => (isSubscribed = false);
  }, [authLoading, user, navigate]);

  return (
    <UserContext.Provider value={{ user, authLoading, userData }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object
};
