import illustration1 from 'assets/img/icons/spot-illustrations/50.png';
import illustration2 from 'assets/img/icons/spot-illustrations/49.png';
import illustration3 from 'assets/img/icons/spot-illustrations/48.png';
import illustration4 from 'assets/img/icons/spot-illustrations/41.png';

/**
 * This file is used to store the data for process list
 * The processes should talk about TradeHub and how it can help the user
 * to achieve their goals. The processes should be in a logical order.
 * It should start with listing products with AI enhanced data, then
 * move on to inventory management and analytics, and finally end with
 * online store capabilities (ecommerce)
 */

export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'MANAGE',
    color: 'danger',
    title: 'Product Information Mangagement',
    description:
      'With TradeHub, you can easily list your products with AI enhanced data. ',
    image: illustration1
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'ANALYZE',
    color: 'info',
    title: 'Inventory Management and Analytics',
    description:
      "Use TradeHub's advanced inventory management, purchase ordering and invoicing capabilities to simplify your business.",
    image: illustration2,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'SELL',
    color: 'success',
    title: 'Simple E-Commerce',
    description:
      'TradeHub can help you create a simple online store to sell your products. You can also integrate with your existing online store',
    image: illustration3
  },
  {
    icon: ['fas', 'layer-group'],
    iconText: 'MULTI-CHANNEL',
    color: 'success',
    title: 'List Everything, Everywhere',
    description:
      'TradeHub lists your products across multiple channels, including your own online store, Amazon, eBay, and more.',
    image: illustration4,
    inverse: true
  }
];
