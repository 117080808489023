/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Image, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { api } from 'config/firebase';
import { connectFunctionsEmulator } from 'firebase/functions';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';
import { Loading } from 'components/common/Loading';

connectFunctionsEmulator(api, 'localhost', 5001);

const Orders = () => {
  const [apiGetWandaOrders, loading, error] = useHttpsCallable(
    api,
    'getWandaOrders'
  );
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.log(error);
      return;
    }
    apiGetWandaOrders({ orgId: 496330, limit: 500 })
      .then(result => {
        //console.log(result.data.orders);
        setOrders(result.data.orders);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  /**
   * <Button variant="outline-primary" size="sm">
      <Link to="/products/add">Add Product</Link>
     </Button>
   */

  return (
    <Card style={{ minHeight: '20vh' }}>
      <div className="align-items-center mt-1 ps-1 ms-1">
        <div className="d-flex justify-content-start">
          <h5 className="mb-0">Orders</h5>
        </div>
      </div>
      {!loading ? (
        <div className="m-2">
          <OrdersTable orders={orders} />
        </div>
      ) : (
        <Loading
          messages={[
            'recent orders',
            'product catalog',
            'sales data',
            'logistics information',
            'order analytics'
          ]}
        />
      )}
    </Card>
  );
};

const DisplayItem = ({ item }) => {
  const { unitPrice, imageUrl, name, quantity } = item;

  return (
    <Row className="align-items-center">
      <Col xs="auto" style={{ minWidth: '60px' }}>
        {unitPrice && unitPrice > 0 && (
          <Image
            src={imageUrl}
            className="rounded"
            width="50"
            height="50"
            alt={name}
          />
        )}
      </Col>
      <Col>
        {unitPrice && unitPrice < 0 ? (
          <Badge className="ms-3">Discount Code: {name}</Badge>
        ) : (
          <div
            className="d-inline-block text-truncate"
            style={{ maxWidth: '400px' }}
          >
            {quantity} x {name}
          </div>
        )}{' '}
      </Col>
    </Row>
  );
};

const DisplayItemList = ({ items }) => {
  return (
    <div>
      {items.map((item, i) => (
        <DisplayItem key={`${i}a`} item={item} />
      ))}
    </div>
  );
};

const OrdersTable = ({ orders }) => {
  const columns = [
    {
      accessor: 'orderNumber',
      Header: 'Order #',
      Cell: rowData => {
        const { orderId, orderNumber } = rowData.row.original;
        return <Link to={`/orders/${orderId}`}>{orderNumber}</Link>;
      }
    },
    {
      accessor: 'items',
      Header: 'Items',
      Cell: rowData => {
        const { items } = rowData.row.original;
        return <DisplayItemList items={items} />;
      }
    },
    {
      accessor: 'modifyTimestamp._seconds',
      Header: 'Date & Time',
      Cell: rowData => {
        const { modifyTimestamp } = rowData.row.original;
        const date = new Date(modifyTimestamp._seconds * 1000);
        return date.toLocaleString();
      }
    },
    {
      accessor: 'orderStatus',
      Header: 'Status',
      Cell: rowData => {
        const { orderStatus } = rowData.row.original;
        let statusMessage = '';
        let statusColor = '';
        switch (orderStatus) {
          case 'shipped':
            statusMessage = 'Shipped';
            statusColor = 'success';
            break;
          case 'cancelled':
            statusMessage = 'Cancelled';
            statusColor = 'danger';
            break;
          default:
            statusMessage = 'Pending';
            statusColor = 'info';
            break;
        }
        return <SoftBadge bg={statusColor}>{statusMessage}</SoftBadge>;
      }
    },
    {
      accessor: 'shipTo.city',
      Header: 'City'
    },
    {
      accessor: 'shipTo.state',
      Header: 'State'
    },
    {
      accessor: 'shipTo.postalCode',
      Header: 'Postal Code'
    },
    {
      accessor: 'orderTotal',
      Header: 'Total',
      Cell: rowData => {
        const { orderTotal } = rowData.row.original;
        return <div>{`$${orderTotal}`}</div>;
      }
    }
  ];

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orders}
      sortable
      pagination
      perPage={10}
    >
      <Row className="mb-1">
        <Col xs={12}>
          <AdvanceTableSearchBox table />
        </Col>
      </Row>
      <AdvanceTable
        table
        headerClassName="bg-200 text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          bordered: true,
          striped: true,
          className: 'fs--1 mb-0'
        }}
      />
      <div className="mt-3">
        <AdvanceTableFooter
          rowCount={orders?.length}
          table
          rowInfo
          navButtons
          rowsPerPageSelection
        />
      </div>
    </AdvanceTableWrapper>
  );
};

OrdersTable.propTypes = {
  orders: PropTypes.array
};

export default Orders;
