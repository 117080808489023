import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';

const MailConfirmedContent = ({ titleTag: TitleTag }) => (
  <>
    <img
      className="d-block mx-auto mb-4"
      src={envelope}
      alt="sent"
      width={100}
    />
    <TitleTag>Thank you!</TitleTag>
    <p>
      Thank you for confirming your email address. You can now login to your
      account.
    </p>
    <Button as={Link} color="primary" size="sm" className="mt-3" to={`/login`}>
      <FontAwesomeIcon
        icon="chevron-left"
        transform="shrink-4 down-1"
        className="me-1"
      />
      Return to login
    </Button>
  </>
);

MailConfirmedContent.propTypes = {
  titleTag: PropTypes.string
};

MailConfirmedContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default MailConfirmedContent;
