import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import { useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';

const ErrorStep = ({ setStep }) => {
  const [authLoading, user] = useOutletContext();

  const lastStep = () => {
    const currentStep = user.onBoarding.currentStep;
    if (!currentStep) return 1;
    return Number(currentStep) - 1;
  };

  return (
    !authLoading &&
    user && (
      <Card>
        <Card.Body className="overflow-hidden p-lg-6 pt-3 mt-3">
          <Row className="align-items-center justify-content-between">
            <Col lg={6}>
              <img src={editing} className="img-fluid" alt="" />
            </Col>
            <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
              <h3 className="text-primary">Oops! Somethings Wrong</h3>
              <p className="lead">
                We're sorry, but something went wrong. Please try again later.
              </p>
              <Button
                variant="falcon-primary"
                onClick={() => setStep(lastStep)}
              >
                Return to Onboarding
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  );
};

export default ErrorStep;

ErrorStep.propTypes = {
  setStep: PropTypes.func.isRequired
};
