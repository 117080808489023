import React from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import { logout } from 'config/firebase';

const Logout = () => {
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) logout();
    return () => (isSubscribed = false);
  }, []);

  return (
    <div className="text-center">
      <LogoutContent />
    </div>
  );
};

export default Logout;
