import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import ProductShipperSpecs from './ProductShipperSpecs';

const ProductDisplayOrShipper = () => {
  const { register, errors } = useFormContext();
  const [getAdditionalInfo, setAdditionalInfo] = React.useState('No');

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Shipper/Display Contents
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Is this a Display and/or Shipper Product?</Form.Label>
              <Form.Select
                isInvalid={!!errors?.displayAndShipperProduct}
                {...register('displayAndShipperProduct')}
                onChange={e => setAdditionalInfo(() => e.target.value)}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.displayAndShipperProduct?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {getAdditionalInfo === 'Yes' ? (
            <>
              <Col>
                <Form.Group>
                  <Form.Label>Assembled Display Dimensions (Inches)</Form.Label>
                  <Row className="gx-2 gy-3">
                    <Col md="4">
                      <Form.Control
                        type="text"
                        name="displayAssembledDimensionsHeight"
                        {...register('displayAssembledDimensionsHeight')}
                        placeholder="Height"
                        size="sm"
                      />
                    </Col>

                    <Col md="4">
                      <Form.Control
                        type="text"
                        name="displayAssembledDimensionsWidth"
                        {...register('displayAssembledDimensionsWidth')}
                        placeholder="Width"
                        size="sm"
                      />
                    </Col>
                    <Col md="4">
                      <Form.Control
                        type="text"
                        name="displayAssembledDimensionsDepth"
                        {...register('displayAssembledDimensionsDepth')}
                        placeholder="Depth"
                        size="sm"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xs="12">
                <ProductShipperSpecs />
              </Col>
            </>
          ) : null}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductDisplayOrShipper;
