import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
// import editing from 'assets/img/icons/spot-illustrations/21.png';
import { useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';
import { progressUserOnboarding } from 'config/firebase';
import Wizard from 'components/pages/onboarding/forms/OnboardingWizard';
import PageHeader from 'components/common/PageHeader';

const PaymentDetailsStep = ({ setStep }) => {
  const [authLoading, user] = useOutletContext();

  const handleNext = async () => {
    await progressUserOnboarding(user.uid, 4);
    setStep(4);
  };

  return (
    !authLoading &&
    user && (
      <>
        <Card className="border-0 bg-transparent shadow-none">
          <Card.Header>
            <PageHeader title="Account, Payment & Ordering Details" />
          </Card.Header>
          <Card.Body>
            <Row className="align-items-center">
              <Col>
                <Wizard
                  variant="pills"
                  validation
                  progressBar
                  userData={user}
                  authLoading={authLoading}
                  handleNext={handleNext}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    )
  );
};

export default PaymentDetailsStep;

PaymentDetailsStep.propTypes = {
  setStep: PropTypes.func.isRequired
};
