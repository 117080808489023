import React, { useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProductTags = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tags'
  });
  const [specification, setSpecification] = useState({
    tag: ''
  });

  const handleTagInputKeyDown = e => {
    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault(); // Prevent form submission
      addTag();
    }
  };

  const addTag = () => {
    if (specification.tag.trim() !== '') {
      append({
        tag: specification.tag
      });
      setSpecification({
        tag: ''
      });
    }
  };

  return (
    <Card className="mb-3 mb-lg-0">
      <Card.Header as="h6" className="bg-light">
        Product Tags
      </Card.Header>
      <Card.Body>
        <div className="mb-4">Add keywords for your product below</div>
        {fields.map((specification, index) => (
          <Row key={index} className="gx-2 flex-between-center mb-3">
            <Col sm={12}>
              <Flex justifyContent="between" alignItems="center">
                <h6 className="mb-0 text-700">{specification.tag}</h6>
                <Button
                  variant="link"
                  to="#!"
                  type="button"
                  className="text-danger"
                  size="sm"
                  onClick={() => remove(index)}
                >
                  <FontAwesomeIcon className="fs--1" icon="trash-alt" />
                </Button>
              </Flex>
            </Col>
          </Row>
        ))}
        <Row className="gy-3 gx-2">
          <Col sm={12}>
            <Flex
              justifyContent="between"
              alignItems="center"
              className="gap-2"
            >
              <Form.Control
                type="text"
                size="sm"
                name="specificationProperty"
                placeholder="Property"
                value={specification.tag}
                onChange={e =>
                  setSpecification({
                    tag: e.target.value
                  })
                }
                onKeyDown={handleTagInputKeyDown}
              />
              <Button
                variant="falcon-default"
                size="sm"
                className="me-2"
                type="button"
                disabled={specification.tag === ''}
                onClick={() => {
                  addTag();
                }}
              >
                Add
              </Button>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductTags;
