import React from 'react';
import { Card, Col, Form, Row, InputGroup } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ProductAllowances = () => {
  const { register, errors } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Allowances
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col xs="8">
            <span className="fs--2 fw-bold"></span>
          </Col>
          <Col xs="2">
            <span className="fs--2 fw-bold">Master Case or Each</span>
          </Col>
          <Col xs="2">
            <span className="fs--2 fw-bold">Bill Back or Off Invoice</span>
          </Col>
        </Row>
        <Row className="gx-2 gy-3">
          <Col xs="8">
            <InputGroup className="mb-3">
              <InputGroup.Text id="ba1">Allowance: $</InputGroup.Text>
              <Form.Control
                type="text"
                isInvalid={!!errors?.allowanceAmount}
                {...register('allowanceAmount')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.allowanceAmount?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col xs="2">
            <Form.Select
              isInvalid={!!errors?.allowanceSizeType}
              {...register('allowanceSizeType')}
            >
              <option value="">Select</option>
              <option value="Percentage">Master Case</option>
              <option value="Dollar">Each</option>
            </Form.Select>
          </Col>
          <Col xs="2">
            <Form.Select
              isInvalid={!!errors?.allowanceBillType}
              {...register('allowanceBillType')}
            >
              <option value="">Select</option>
              <option value="Percentage">Bill Back</option>
              <option value="Dollar">Off Invoice</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="gx-2 gy-3">
          <Col xs="8">
            <span className="fs--2 fw-bold"></span>
          </Col>
          <Col xs="2">
            <span className="fs--2 fw-bold">Master Case or Each</span>
          </Col>
          <Col xs="2">
            <span className="fs--2 fw-bold">Bill Back or Off Invoice</span>
          </Col>
        </Row>
        <Row className="gx-2 gy-3">
          <Col xs="8">
            <InputGroup className="mb-3">
              <InputGroup.Text id="ba1">Spiff Allowance: $</InputGroup.Text>
              <Form.Control
                type="text"
                isInvalid={!!errors?.spiffAllowanceAmount}
                {...register('spiffAllowanceAmount')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.allowanceAmount?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col xs="2">
            <Form.Select
              isInvalid={!!errors?.allowanceType}
              {...register('spiffAllowanceSizeType')}
            >
              <option value="">Select</option>
              <option value="Percentage">Master Case</option>
              <option value="Dollar">Each</option>
            </Form.Select>
          </Col>
          <Col xs="2">
            <Form.Select
              isInvalid={!!errors?.allowanceType}
              {...register('spiffAllowanceBillType')}
            >
              <option value="">Select</option>
              <option value="Percentage">Bill Back</option>
              <option value="Dollar">Off Invoice</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductAllowances;
