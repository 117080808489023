import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext, useForm } from 'react-hook-form';
import { levelOne, levelTwo } from 'data/categories';

const ProductType = () => {
  const [selectedLevelTwo, setSelectedLevelTwo] = React.useState([]);
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const { setValue } = useForm();

  function handleLevelOneChange(e) {
    const selectedLevel = levelOne.filter(
      item => item.categoryName === e.target.value
    );
    setValue('productSubCategory', '', { shouldValidate: true });
    setValue('productCategory', selectedLevel.categoryName, {
      shouldValidate: true
    });
    const selectedLevelTwo = levelTwo[Number(selectedLevel[0].id - 1)];
    setSelectedLevelTwo(selectedLevelTwo[selectedLevel[0].id]);
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Select Category
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Main Category:</Form.Label>
              <Form.Select
                {...register(`productCategory`)}
                isInvalid={!!errors.productCategory}
                size="sm"
                onChange={handleLevelOneChange}
              >
                <option value="">Select</option>
                {levelOne.map((item, index) => (
                  <option key={index} value={item.categoryName}>
                    {item.categoryName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.productCategory?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Select sub-category:</Form.Label>
              <Form.Select
                {...register(`productSubCategory`)}
                isInvalid={!!errors.productSubCategory}
                size="sm"
              >
                <option value="">Select</option>
                {selectedLevelTwo.map((item, index) => (
                  <option key={index} value={item.categoryName}>
                    {item.categoryName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.productSubCategory?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductType;
