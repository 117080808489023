import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../../../wizard/WizardInput';
import { Col, Row, Form } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OrderingPreferencesForm = ({ register, errors }) => {
  return (
    <>
      <Row className="mb-3">
        <Col>
          <WizardInput
            type="text"
            label="Terms of Invoice"
            name="invoiceTerms"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('invoiceTerms')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Number of Days Due"
            name="invoiceDaysDue"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('invoiceDaysDue')
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Back Order?"
            name="invoiceTerms"
            placeholder="Select..."
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('invoiceTerms')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Minimum Ship Requirements"
            name="minimumShipRequirements"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('minimumShipRequirements')
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Frieght Included?"
            name="frieghtIncluded"
            placeholder="Select..."
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('freightIncluded')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Lead Time"
            name="leadTime"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('leadTime')
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Is Pickup Available?"
            name="pickupAvailable"
            placeholder="Select..."
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('pickupAvailable')
            }}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <WizardInput
                type="text"
                label="Pickup Order Minimum in Cases"
                name="minPickupCases"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('minPickupCases')
                }}
              />
            </Col>
            <Col>
              <WizardInput
                type="text"
                label="Pickup Order Minimum in Pounds"
                name="minPickupPounds"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('minPickupPounds')
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <WizardInput
            type="text"
            label="Pickup Physical Street Address"
            name="minPickupPounds"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('minPickupPounds')
            }}
          />
        </Col>
      </Row>
      <Row className="g-2 mb-3">
        <Col>
          <WizardInput
            type="text"
            label="City"
            name="city"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('pickupCity')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="select"
            label="State"
            name="state"
            placeholder="Select State"
            options={[
              { label: 'Alabama', value: 'AL' },
              { label: 'Alaska', value: 'AK' },
              { label: 'Arizona', value: 'AZ' },
              { label: 'Arkansas', value: 'AR' },
              { label: 'California', value: 'CA' },
              { label: 'Colorado', value: 'CO' },
              { label: 'Connecticut', value: 'CT' },
              { label: 'Delaware', value: 'DE' },
              { label: 'Florida', value: 'FL' },
              { label: 'Georgia', value: 'GA' },
              { label: 'Hawaii', value: 'HI' },
              { label: 'Idaho', value: 'ID' },
              { label: 'Illinois', value: 'IL' },
              { label: 'Indiana', value: 'IN' },
              { label: 'Iowa', value: 'IA' },
              { label: 'Kansas', value: 'KS' },
              { label: 'Kentucky', value: 'KY' },
              { label: 'Louisiana', value: 'LA' },
              { label: 'Maine', value: 'ME' },
              { label: 'Maryland', value: 'MD' },
              { label: 'Massachusetts', value: 'MA' },
              { label: 'Michigan', value: 'MI' },
              { label: 'Minnesota', value: 'MN' },
              { label: 'Mississippi', value: 'MS' },
              { label: 'Missouri', value: 'MO' },
              { label: 'Montana', value: 'MT' },
              { label: 'Nebraska', value: 'NE' },
              { label: 'Nevada', value: 'NV' },
              { label: 'New Hampshire', value: 'NH' },
              { label: 'New Jersey', value: 'NJ' },
              { label: 'New Mexico', value: 'NM' },
              { label: 'New York', value: 'NY' },
              { label: 'North Carolina', value: 'NC' },
              { label: 'North Dakota', value: 'ND' },
              { label: 'Ohio', value: 'OH' },
              { label: 'Oklahoma', value: 'OK' },
              { label: 'Oregon', value: 'OR' },
              { label: 'Pennsylvania', value: 'PA' },
              { label: 'Rhode Island', value: 'RI' },
              { label: 'South Carolina', value: 'SC' },
              { label: 'South Dakota', value: 'SD' },
              { label: 'Tennessee', value: 'TN' },
              { label: 'Texas', value: 'TX' },
              { label: 'Utah', value: 'UT' },
              { label: 'Vermont', value: 'VT' },
              { label: 'Virginia', value: 'VA' },
              { label: 'Washington', value: 'WA' },
              { label: 'West Virginia', value: 'WV' },
              { label: 'Wisconsin', value: 'WI' },
              { label: 'Wyoming', value: 'WY' }
            ]}
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('pickupState')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Postal Code"
            name="postalCode"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('pickupPostalCode')
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Appointment Required?"
            name="appointmentRequired"
            placeholder="Select..."
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('appointmentRequired')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Appointment Contact Phone Number"
            name="appointmentContactPhone"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('appointmentContactPhone')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Orders Sent To:</Form.Label>
        </Col>
      </Row>
      <Row className="">
        <Col>
          <WizardInput
            type="text"
            label="Ordering Contact Name"
            name="orderingContactName"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('orderingContactName')
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Preferred Method of Order"
            name="preferredMethodOfOrder"
            placeholder="Select..."
            options={[
              { value: 'Email', label: 'Email' },
              { value: 'Fax', label: 'Fax' },
              { value: 'Phone', label: 'Phone' },
              { value: 'EDI', label: 'EDI' },
              { value: 'Other', label: 'Other' }
            ]}
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('preferredMethodOfOrder')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Preferred Method Details (Email/Fax/Phone/Etc)"
            name="preferredMethodDetails"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('preferredMethodDetails')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <WizardInput
            type="select"
            label="Will you ship less than minimum for the first order (with approved promotions)?"
            name="shipLessThanMinimum"
            placeholder="Select..."
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('shipLessThanMinimum')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Broker or Contact Information</Form.Label>
        </Col>
      </Row>
      <Row>
        <WizardInput
          type="text"
          label="Name"
          name="brokerOrContactName"
          placeholder="Please enter the contact name"
          errors={errors}
          formGroupProps={{
            className: 'mb-3'
          }}
          formControlProps={{
            className: 'input-spin-none',
            ...register('brokerOrContactName')
          }}
        />
      </Row>
      <WizardInput
        type="text"
        label="Contact Street Address"
        name="brokerContactAddress"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('brokerContactAddress')
        }}
      />
      <Row className="g-2 mb-3">
        <Col>
          <WizardInput
            type="text"
            label="City"
            name="city"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('brokerCity')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="select"
            label="State"
            name="state"
            placeholder="Select State"
            options={[
              { label: 'Alabama', value: 'AL' },
              { label: 'Alaska', value: 'AK' },
              { label: 'Arizona', value: 'AZ' },
              { label: 'Arkansas', value: 'AR' },
              { label: 'California', value: 'CA' },
              { label: 'Colorado', value: 'CO' },
              { label: 'Connecticut', value: 'CT' },
              { label: 'Delaware', value: 'DE' },
              { label: 'Florida', value: 'FL' },
              { label: 'Georgia', value: 'GA' },
              { label: 'Hawaii', value: 'HI' },
              { label: 'Idaho', value: 'ID' },
              { label: 'Illinois', value: 'IL' },
              { label: 'Indiana', value: 'IN' },
              { label: 'Iowa', value: 'IA' },
              { label: 'Kansas', value: 'KS' },
              { label: 'Kentucky', value: 'KY' },
              { label: 'Louisiana', value: 'LA' },
              { label: 'Maine', value: 'ME' },
              { label: 'Maryland', value: 'MD' },
              { label: 'Massachusetts', value: 'MA' },
              { label: 'Michigan', value: 'MI' },
              { label: 'Minnesota', value: 'MN' },
              { label: 'Mississippi', value: 'MS' },
              { label: 'Missouri', value: 'MO' },
              { label: 'Montana', value: 'MT' },
              { label: 'Nebraska', value: 'NE' },
              { label: 'Nevada', value: 'NV' },
              { label: 'New Hampshire', value: 'NH' },
              { label: 'New Jersey', value: 'NJ' },
              { label: 'New Mexico', value: 'NM' },
              { label: 'New York', value: 'NY' },
              { label: 'North Carolina', value: 'NC' },
              { label: 'North Dakota', value: 'ND' },
              { label: 'Ohio', value: 'OH' },
              { label: 'Oklahoma', value: 'OK' },
              { label: 'Oregon', value: 'OR' },
              { label: 'Pennsylvania', value: 'PA' },
              { label: 'Rhode Island', value: 'RI' },
              { label: 'South Carolina', value: 'SC' },
              { label: 'South Dakota', value: 'SD' },
              { label: 'Tennessee', value: 'TN' },
              { label: 'Texas', value: 'TX' },
              { label: 'Utah', value: 'UT' },
              { label: 'Vermont', value: 'VT' },
              { label: 'Virginia', value: 'VA' },
              { label: 'Washington', value: 'WA' },
              { label: 'West Virginia', value: 'WV' },
              { label: 'Wisconsin', value: 'WI' },
              { label: 'Wyoming', value: 'WY' }
            ]}
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('brokerState')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Postal Code"
            name="postalCode"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('brokerPostalCode')
            }}
          />
        </Col>
      </Row>
      <Row className="g-2 mb-3">
        <Col>
          <WizardInput
            type="text"
            label="Phone Number"
            name="phone1"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('brokerPhone1')
            }}
          />
        </Col>
        <Col>
          <WizardInput
            type="text"
            label="Fax Number"
            name="fax1"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('brokerFax1')
            }}
          />
        </Col>
      </Row>
      <Row>
        <WizardInput
          type="text"
          label="Email Address"
          name="brokerEmail"
          placeholder="Please enter the contact email address"
          errors={errors}
          formGroupProps={{
            className: 'mb-3'
          }}
          formControlProps={{
            className: 'input-spin-none',
            ...register('brokerEmail')
          }}
        />
      </Row>
    </>
  );
};

OrderingPreferencesForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default OrderingPreferencesForm;
