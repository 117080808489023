/* eslint-disable react/prop-types */
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import React from 'react';
import { ProgressBar, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const getProductItemCalculatedData = (totalSales, totalPrice) => {
  const productTotalPrice = totalSales;
  const percentage = ((productTotalPrice * 100) / totalPrice).toFixed(0);
  return { productTotalPrice, percentage };
};

const BestSellingProduct = ({ product, totalPrice, isLast }) => {
  const { primaryImage, name, type, totalUnitsSold, totalSales, sku } = product;
  const { productTotalPrice, percentage } = getProductItemCalculatedData(
    totalSales,
    totalPrice
  );

  return (
    <tr className={classNames({ 'border-bottom border-200': !isLast })}>
      <td>
        <Flex alignItems="center" className="position-relative">
          <img
            className="rounded-1 border border-200"
            src={primaryImage}
            width="60"
            alt={name}
          />
          <div className="ms-3">
            <h6 className="mb-1 fw-semi-bold">
              <Link className="text-dark stretched-link" to="#!">
                {sku} - {name}
              </Link>
            </h6>
            <p className="fw-semi-bold mb-0 text-500">{type}</p>
          </div>
        </Flex>
      </td>
      <td className="align-middle text-end fw-semi-bold">
        ${Number(productTotalPrice).toFixed(2)} ({totalUnitsSold})
      </td>
      <td className="align-middle pe-x1">
        <Flex alignItems="center">
          <ProgressBar now={percentage} style={{ width: '80px', height: 5 }} />
          <div className="fw-semi-bold ms-3">{percentage}%</div>
        </Flex>
      </td>
    </tr>
  );
};

const BestSellingProducts = ({ products }) => {
  // each product has a totalSales property representign the total sales of that product
  // add up all the totalSales properties to get the total sales of all products
  const totalPrice = products.reduce(
    (total, product) => total + product.totalSales,
    0
  );

  const noOfProducts = products.length;

  return (
    <Table
      borderless
      responsive
      className="mb-0 fs--1 bg-light"
      style={{ backgroundColor: '#FFFFFF' }}
    >
      <thead className="bg-light">
        <tr
          className="text-900 fw-semi-bold"
          style={{ backgroundColor: '#FFFFFF' }}
        >
          <th>Best Selling Products</th>
          <th className="text-end">
            Revenue ( ${Number(totalPrice).toFixed(2)})
          </th>
          <th className="pe-x1 text-end" style={{ width: '8rem' }}>
            Revenue (%)
          </th>
        </tr>
      </thead>
      <tbody style={{ backgroundColor: '#FFFFFF' }}>
        {products.map((product, index) => (
          <BestSellingProduct
            product={product}
            totalPrice={totalPrice}
            isLast={index === noOfProducts - 1}
            key={product.id}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default BestSellingProducts;
