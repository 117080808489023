import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ProductBasicInformation from './ProductBasicInformation';
import ProductHeader from './ProductHeader';
import ProductUpload from './ProductUpload';
import ProductFooter from './ProductFooter';
import ProductDetails from './ProductDetails';
import ProductType from './ProductType';
import ProductTags from './ProductTags';
import ProductPricing from './ProductPricing';
import ProductMasterCase from './ProductMasterCase';
import ProductWeightsDimensions from './ProductWeightsDimensions';
import ProductTiersLayers from './ProductTiersLayers';
import ProductDeliveredCost from './ProductDeliveredCost';
import ProductShelfDetails from './ProductShelfDetails';
import ProductStorage from './ProductStorage';
import ProductDisplayOrShipper from './ProductDisplayOrShipper';
import ProductAllowances from 'components/app/e-commerce/product/add-product/ProductAllowances';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db } from 'config/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useUserContext } from 'context/UserContext';

// eslint-disable-next-line no-unused-vars
const schema = yup
  .object({
    productName: yup.string().required('This field is required.'),
    manufacturerName: yup.string().required('This field is required.'),
    identificationNumber: yup.string().required('This field is required.'),
    productSummery: yup.string().required('This field is required.'),
    productSubCategory: yup.string().required('This field is required.'),
    regularPrice: yup
      .number()
      .transform(value => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('This field is required.'),
    masterUpc: yup.string().required('This field is required.'),
    innerUpc: yup.string().required('This field is required.'),
    eachUpc: yup.string().required('This field is required.'),
    masterCasePack: yup.string().required('This field is required.'),
    innerPack: yup.string().required('This field is required.'),
    eachPack: yup.string().required('This field is required.')
  })
  .required();

const AddProduct = () => {
  // eslint-disable-next-line no-unused-vars
  const { user, authLoading, userData } = useUserContext();
  const navigate = useNavigate();
  const [imagesArray, setImagesArray] = React.useState([]);
  const submittedValues = {};
  const methods = useForm({
    //resolver: yupResolver(schema),
    defaultValues: {}
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = async data => {
    console.log(data);
    if (!userData) {
      toast.error('No User Found', { theme: 'colored' });
      return;
    }
    const newProduct = {
      ...data,
      category: `${data.productCategory} > ${data.productSubCategory}`,
      desc: data.productDescription,
      id: data.identificationNumber,
      isInStock: false,
      isNew: true,
      name: data.productName,
      orgId: userData.orgId,
      price: data.srpPrice,
      shortDescription: data.productSummery,
      tags: data.tags
    };
    const newFiles = [];
    for (let i = 0; i < imagesArray?.length; i++) {
      newFiles.push({
        id: i,
        src: imagesArray[i]
      });
    }
    newProduct.files = newFiles;
    // save file to firebase
    const docRef = await addDoc(collection(db, 'products'), newProduct);
    toast.success(`Product Added Successfully - ${docRef.id}`, {
      theme: 'colored'
    });
    navigate('/products');
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);
    for (const key of keys) {
      submittedValues[key] = '';
    }
    reset({ ...submittedValues });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <ProductHeader />
          </Col>
          <Col lg={8}>
            <ProductBasicInformation />
            <ProductUpload setPics={setImagesArray} />
            <ProductDetails />
            <ProductWeightsDimensions />
            <ProductDisplayOrShipper />
            <ProductAllowances />
            <ProductTags />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <ProductMasterCase />
              <ProductType />
              <ProductShelfDetails />
              <ProductStorage />
              <ProductTiersLayers />
              <ProductDeliveredCost />
              <ProductPricing />
            </div>
          </Col>
          <Col>
            <ProductFooter />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default AddProduct;

/**
 * <ProductSpecifications />
 * <ProductShipping />
 * <ProductStock />
 */
