/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderInfo from './OrderInfo';
import OrderedProducts from './OrderedProducts';
import ShippingDetails from 'components/app/e-commerce/orders/order-details/ShippingDetails';
import SoftBadge from 'components/common/SoftBadge';
import { api } from 'config/firebase';
import { connectFunctionsEmulator } from 'firebase/functions';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { Card, Tabs, Tab } from 'react-bootstrap';
import { Loading } from 'components/common/Loading';
connectFunctionsEmulator(api, 'localhost', 5001);

const OrderDetails = () => {
  const { orderId } = useParams();
  const [apiGetWandaOrder, apiLoading, apiError] = useHttpsCallable(
    api,
    'getWandaOrder'
  );
  const [order, setOrder] = useState([]);
  const [createdDateString, setCreatedDateString] = useState('');
  const [modifiedDateString, setModifiedDateString] = useState('');
  useEffect(() => {
    if (apiLoading) return;
    if (apiError) {
      console.log(apiError);
      return;
    }
    apiGetWandaOrder({ orgId: 496330, orderId: orderId })
      .then(result => {
        setOrder(result.data.order);
        const createTimestamp = new Date(
          result.data.order.createDate
        ).toLocaleString();
        const modifiedTimestamp = new Date(
          result.data.order.modifyDate
        ).toLocaleString();
        setCreatedDateString(createTimestamp);
        setModifiedDateString(modifiedTimestamp);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const DisplayStatus = status => {
    switch (status) {
      case 'shipped':
        return (
          <SoftBadge pill bg="success" className="fs--2">
            {' '}
            Shipped
            <FontAwesomeIcon
              icon="check"
              className="ms-1"
              transform="shrink-2"
            />
          </SoftBadge>
        );
      case 'awaiting_shipment':
        return (
          <SoftBadge pill bg="success" className="fs--2">
            {' '}
            Awaiting Shipment
            <FontAwesomeIcon
              icon="check"
              className="ms-1"
              transform="shrink-2"
            />
          </SoftBadge>
        );
      default:
        return (
          <SoftBadge pill bg="secondary" className="fs--2">
            {' '}
            {status}
            <FontAwesomeIcon
              icon="check"
              className="ms-1"
              transform="shrink-2"
            />
          </SoftBadge>
        );
    }
  };

  return (
    <>
      <PageHeader
        title={
          !apiLoading
            ? `Order Details: #${order.orderNumber}`
            : 'Loading Order Details'
        }
        titleTag="h5"
        className="mb-3"
      >
        <span className="fs--1 mt-1">Created: {createdDateString}</span>
        <p className="fs--1">Last Update: {modifiedDateString}</p>
        <div>
          <strong className="me-2">Status: </strong>
          {DisplayStatus(order?.orderStatus)}
        </div>
      </PageHeader>
      {!apiLoading ? (
        <>
          <OrderInfo order={order} />
          <Card>
            <Tabs
              variant="pills"
              defaultActiveKey="order_items"
              className="ps-2 pe-2 pt-3 mb-3"
            >
              <Tab
                eventKey="order_items"
                title="Order items"
                className="ps-2 pe-2"
              >
                {!apiLoading && <OrderedProducts order={order} />}
              </Tab>
              <Tab
                eventKey="shipping_details"
                title="Fulfillment Details"
                className="ps-3 pe-3 mb-3"
              >
                {!apiLoading && <ShippingDetails order={order} />}
              </Tab>
              <Tab
                eventKey="comments"
                title="Comments"
                className="p-3"
                disabled
              >
                <p>
                  Accuse me thus: that I have scanted all, Wherein I should your
                  great deserts repay, Forgot upon your dearest love to call,
                  Whereto all bonds do tie me day by day; That I have frequent
                  been with unknown minds, And given to time your own
                  dear-purchas'd right;
                </p>
              </Tab>
            </Tabs>
          </Card>
        </>
      ) : (
        <Loading messages={['order details']} />
      )}
    </>
  );
};

export default OrderDetails;
