import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Processes = () => (
  <Section>
    <SectionHeader
      title="Where Supply Meets Sophistication"
      subtitle="Your All-in-One Platform for Trade Excellence. TradeHub is a cloud-based platform that helps you manage your Supplier-Broker business from anywhere. It is designed to help you manage your products, inventory, orders, and customers across multiple channels"
    />
    {isIterableArray(processList) &&
      processList.map((process, index) => (
        <Process
          key={`${process.color}-${index}`}
          isFirst={index === 0}
          {...process}
        />
      ))}
  </Section>
);

export default Processes;
