import React, { useEffect, useState, useContext } from 'react';
import { Navigate, Route, Routes, useNavigate, Outlet } from 'react-router-dom';
import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
// import Starter from 'components/pages/Starter';
// import FontAwesome from 'components/doc-components/FontAwesome';
import Profile from 'components/pages/user/profile/Profile';
import Settings from 'components/pages/user/settings/Settings';
import FaqBasic from 'components/pages/faq/faq-basic/FaqBasic';
import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
import InvitePeople from 'components/pages/miscellaneous/invite-people/InvitePeople';
import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault';
import Invoice from 'components/app/e-commerce/Invoice';
// import Billing from 'components/app/e-commerce/billing/Billing';
// import Checkout from 'components/app/e-commerce/checkout/Checkout';
// import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart';
// import CustomersDetails from 'components/app/e-commerce/customers-details/CustomersDetails';
import OrderDetails from 'components/app/e-commerce/orders/order-details/OrderDetails';
// import Products from 'components/app/e-commerce/product/Products';
import ProductDetails from 'components/app/e-commerce/product/product-details/ProductDetails';
import AddProduct from 'components/app/e-commerce/product/add-product/AddProduct';
// import Orders from 'components/app/e-commerce/orders/order-list/Orders';
import Customers from 'components/app/e-commerce/customers/Customers';
// import GoogleMapExample from 'components/doc-components/GoogleMapExample';
// import LeafletMapExample from 'components/doc-components/LeafletMapExample';
// import ReactIcons from 'components/doc-components/ReactIcons';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleRegistration from 'components/authentication/simple/Registration';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import SimpleMailConfirmed from 'components/authentication/simple/MailConfirmed';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
//import Ecommerce from 'components/dashboards/e-commerce';
import Dashboard from 'components/dashboards/default';
import OnboardingLayout from 'layouts/OnboardingLayout';
import Onboarding from 'components/pages/onboarding/Onboarding';
import Products from 'components/pages/products/Products';
import Orders from 'components/pages/orders/Orders';
import Reports from 'components/pages/reports/Reports';
import Inventory from 'components/pages/inventory/Inventory';
import ProductsCMS from 'components/pages/productsNew/Products';

import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'config/firebase';

function OnboardingAppWrapper() {
  const { authLoading, user } = useContext(FirebaseAuthContext);
  const [userData, setUserData] = useState(null);
  const [onboardingState, setOnboardingState] = useState(null);
  const navigate = useNavigate();

  async function checkStatus(uid) {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    const user = userDoc.data();
    setUserData(user);
    if (user.onBoarding?.onboardingState) {
      setOnboardingState(user.onBoarding?.onboardingState);
    }
  }

  useEffect(() => {
    let isSubscribed = true;
    if (authLoading) return;
    if (!user) navigate('/login');
    if (user) {
      if (isSubscribed) checkStatus(user.uid);
    }
    return () => (isSubscribed = false);
  }, [authLoading, user, navigate]);

  useEffect(() => {
    if (!onboardingState) return;
    if (onboardingState !== 'COMPLETE') {
      navigate('/onboarding');
    }
  }, [onboardingState, navigate]);

  return <Outlet context={[user, authLoading, userData]} />;
}

const FalconRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route element={<AuthSimpleLayout />}>
        <Route path="/login" element={<SimpleLogin />} />
        <Route path="/register" element={<SimpleRegistration />} />
        <Route path="/logout" element={<SimpleLogout />} />
        <Route path="/forgot-password" element={<SimpleForgetPassword />} />
        <Route path="/reset-password" element={<SimplePasswordReset />} />
        <Route path="/confirm-mail" element={<SimpleConfirmMail />} />
        <Route path="/mail-confirmed" element={<SimpleMailConfirmed />} />
        <Route path="/secured" element={<SimpleLockScreen />} />
      </Route>
      <Route path="/*" element={<OnboardingAppWrapper />}>
        <Route element={<MainLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="orders/:orderId" element={<OrderDetails />} />
          <Route path="orders" element={<Orders />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="customers" element={<Customers />} />
          <Route path="products" element={<Products />} />
          <Route path="new-products" element={<ProductsCMS />} />
          <Route path="products/:productId" element={<ProductDetails />} />
          <Route path="products/new" element={<AddProduct />} />
          <Route path="inventory" element={<Inventory />} />
          <Route path="user/settings" element={<Settings />} />
          <Route path="user/profile" element={<Profile />} />
          <Route path="reports" element={<Reports />} />
          <Route path="faq/" element={<FaqBasic />} />
          <Route path="pricing" element={<PricingDefault />} />
          <Route path="policies/privacy" element={<PrivacyPolicy />} />
          <Route path="invite" element={<InvitePeople />} />
          <Route element={<ErrorLayout />}>
            <Route path="errors/404" element={<Error404 />} />
            <Route path="errors/500" element={<Error500 />} />
          </Route>
        </Route>
      </Route>
      <Route path="/onboarding" element={<OnboardingLayout />}>
        <Route index element={<Onboarding />} />
      </Route>
      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
